import { equipmentList } from './equipmentList';
import { factoryImageList } from './factoryImageList';
import { productionHistoryList } from './productionHistoryList';
import { timingList } from './timingList';
import { ownerList } from './ownerList';

export class manufacturerProfile {
    //constructor
    constructor() { }

    //fields
    public id: number;
    public profileName: string;
    public primaryEmail: string;
    public secondryEmail: string;
    public companyName: string;
    public companyAddress: string;
    public isVerified: string;
    public primaryPhoneDialCode: string;
    public primaryPhone: string;
    public secondaryPhoneDialCode: string;
    public secondryPhone: string;
    public facebook: string;
    public linkedIn: string;
    public rating: string;
    public totalReviews: string;
    public about: string;
    public establishedYear: string;
    public employees: string;
    public mainIndustries: string;
    public languages: string;
    public latitude: number;
    public longitude: number;
    public equipmentList: Array<equipmentList> = new Array<equipmentList>();
    public factoryImagesList: Array<factoryImageList> = new Array<factoryImageList>();
    public ownerList: Array<ownerList> = new Array<ownerList>();
    public productionHistoryList: Array<productionHistoryList> = new Array<productionHistoryList>();
    public timingList: Array<timingList> = new Array<timingList>();
    public projectReviewRatingList: Array<any>=new Array<any>();
}