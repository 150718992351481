import { Injectable } from '@angular/core';
import * as JWT from 'jwt-decode';
import { Router } from '@angular/router';
import { HttpRequest } from '@angular/common/http';
@Injectable()
export class JwtService {

  constructor(private router: Router) { }
  cachedRequests: Array<HttpRequest<any>> = [];

  public collectFailedRequest(request): void {
    this.cachedRequests.push(request);
  }
  public retryFailedRequests(): void {
    // retry the requests. this method can
    // be called after the token is refreshed
  }
  getToken(): string {
    switch (this.isTokenAvailable()) {
      case 0: return '';
      case 1: return JSON.parse(window.localStorage['SDF_TOKEN']).token;
      case 2: return JSON.parse(window.sessionStorage['SDF_TOKEN']).token;
    }
  }
  getTokenData(): string {
    switch (this.isTokenAvailable()) {
      case 0: return '';
      case 1: return JSON.parse(window.localStorage['SDF_TOKEN']);
      case 2: return JSON.parse(window.sessionStorage['SDF_TOKEN']);
    }
  }
  isAuthenticated(): boolean {
    return this.isTokenAvailable() > -1;
  }
  saveUserRole(_role: string): void {
    localStorage.setItem("userRole", _role);
  }
  updateCurrentSection(_section) {
    var _tokenData = this.getTokenData();
    _tokenData["currentSection"] = _section;
    this.saveToken(_tokenData);
  }
  retrieveUserRole(): string {
    var _role = localStorage.getItem("userRole")
    if (_role) {
      return _role;
    }
  }
  removeUserRole(): void {
    var _role = localStorage.getItem("userRole")
    if (_role) {
      localStorage.removeItem("userRole");
    }
  }
  getUserRole(): number {
    const sdfToken = this.getToken();
    if (sdfToken != '') {
      const decoded = JWT(sdfToken);
      return parseInt(decoded.UserRoleId);
    }
    else {
      return 0;
    }
  }
  setMessageData(data): void {
    this.removeMessageData();
    localStorage.setItem("messageData", data);
  }
  getMessageData(): any {
    var _data = localStorage.getItem("messageData");
    if (_data) {
      return _data;
    }
  }
  removeMessageData(): void {
    var messageData = localStorage.getItem("messageData");
    if (messageData) {
      localStorage.removeItem("messageData");
    }
  }
  getUserRoute(): string {
    switch (this.getUserRole()) {
      case 1: return 'tenant';
      case 2: return 'company';
      case 3: return 'consumer';
      default: return '';
    }
  }

  saveToken(data: any, isRemind: boolean = true) {
    if (isRemind) {
      window.localStorage.clear();
      window.localStorage['SDF_TOKEN'] = JSON.stringify(data);
    } else {
      // window.sessionStorage.clear();
      // window.sessionStorage['SDF_TOKEN'] = JSON.stringify(data);
      window.localStorage.clear();
      window.localStorage['SDF_TOKEN'] = JSON.stringify(data);
    }
    this.router.navigate([this.getUserRoute()]);
  }

  updateProfileImage(data: any, isRemind: boolean = true) {
    if (isRemind) {
      window.localStorage.clear();
      window.localStorage['SDF_TOKEN'] = JSON.stringify(data);
    } else {
      window.sessionStorage.clear();
      window.sessionStorage['SDF_TOKEN'] = JSON.stringify(data);
    }
  }

  deleteToken(onDashboard = false) {
    window.localStorage.clear();
    window.sessionStorage.clear();
    if (!onDashboard) {
      this.router.navigate(['/main', 'dashboard']);
    }
  }

  isTokenAvailable(): number {
    try {
      if (window.localStorage['SDF_TOKEN']) {
        const sdfToken = JSON.parse(window.localStorage['SDF_TOKEN']);
        if (sdfToken.token) {
          const decoded = JWT(sdfToken.token);
          return 1;
        }
        else {
          return 0;
        }
      }
      else if (window.sessionStorage['SDF_TOKEN']) {
        const sdfToken = JSON.parse(window.sessionStorage['SDF_TOKEN']);
        if (sdfToken.token) {
          const decoded = JWT(sdfToken.token);
          return 2;
        }
        else {
          return 0;
        }
      }
      else {
        return 0;
      }
    }
    catch (error) {
      return 0;
    }
  }
  public checkIfProjectIdExistInToken() {
    var _result = this.getTokenData();
    if (_result) {
      return _result["projectId"];
    }
  }
  public removeProjectFromToken() {
    var _result = this.getTokenData();
    if (_result) {
      _result["projectId"] = null;
    }
    this.saveToken(_result)
  }
  public saveProjectIdInStorage(projectId) {
    localStorage.setItem("projectId", projectId);
  }
  public removeProjectIdFromStorage() {
    var _data = localStorage.getItem("projectId")
    if (_data) {
      localStorage.removeItem("projectId");
    }
  }
  public getProjectIdFromStorage() {
    var _data = localStorage.getItem("projectId")
    if (_data) {
      return localStorage.getItem("projectId")
    }
  }
}
