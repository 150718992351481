import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { ResultModel } from '../Models/Result-Model';
import { SignUpModel } from '../Models/SignUpModel';
import { Injectable } from '@angular/core';
import { EmailConfirmationModel } from '../Models/EmailConfirmationModel';
import { LinkedInSignUpModel } from '../Models/LinkedInSignupModel';
import { PasswordResetModel } from '../Models/PasswordResetModel';
import { HelpModel } from '../Models/HelpModel';

@Injectable()
export class SignUpService {

    constructor(private _apiService: ApiService) { }

    public CheckEmailAvailability(_email: string): Observable<ResultModel> {
        var path = "/Account/CheckEmailAvailability";
        var email = { "Email": _email };
        return this._apiService.post<ResultModel>(path, email);
    }
    public SubmitForm(_model: SignUpModel): Observable<ResultModel> {
        var path = "/Account/SignUp";
        return this._apiService.post(path, _model);
    }
    public SignUpManufacturer(_model: SignUpModel): Observable<ResultModel> {
        var path = "/Account/ManufacturerSignUp";
        return this._apiService.post(path, _model);
    }
    public SignUpInventor(_model: SignUpModel): Observable<ResultModel> {
        var path = "/Account/InventorSignUp";
        return this._apiService.post(path, _model);
    }
    public EmailConfirmation(_model: EmailConfirmationModel): Observable<ResultModel> {
        var path = "/Account/EmailConfirmation";
        return this._apiService.post(path, _model);
    }
    public SignIn(_username: string, _password: string, _rememberme: string) {
        return this._apiService.signIn(_username, _password, _rememberme);
    }
    public LinkedInSignUp(_linkedInSignUpModel: LinkedInSignUpModel): Observable<ResultModel> {
        var path = "/Account/LinkedInAccessToken";
        return this._apiService.post(path, _linkedInSignUpModel);
    }
    public ForgotPassword(_email: string): Observable<ResultModel> {
        var path = "/Account/ForgotPassword";
        return this._apiService.post(path, { "Email": _email });
    }
    public PasswordReset(_passrestPassword: PasswordResetModel): Observable<ResultModel> {
        var path = "/Account/ResetPassword";
        return this._apiService.post(path, _passrestPassword);
    }
    public ResendConfirmation(_email: string, projectId: string) {
        var path = "/Account/ResendConfirmationEmail";
        return this._apiService.post(path, { "Email": _email, "projectId": projectId });
    }
    public updateStripePlan(postData: any): Observable<ResultModel> {
        var path = "/Account/UpdateStripePlan";
        return this._apiService.post(path, postData);
    }
    public GetStripePlans() {
        var path = "/Account/GetStripePlans";
        return this._apiService.get<ResultModel>(path);
    }

    public SendHelpEmail(model: HelpModel): Observable<ResultModel> {
        const path = '/Account/SendHelpEmail';
        return this._apiService.post(path, model);
    }

    public UpdatePrimaryEmail(model: any): Observable<ResultModel> {
        const path = '/Common/UpdatePrimaryEmail';
        return this._apiService.post(path, model);
    }

    public getManufacturerDetails(token, id): Observable<ResultModel> {
        const path = `/Manufacturer/ManufacturerDetailForSignUp?ManufacturerId=${id}&Token=${token}`;
        return this._apiService.get(path);
    }
}
