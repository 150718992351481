import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
import { CommponMethod } from 'src/app/core/Helper/CommonMethods';

import { ManufacturerService } from '../../services/manufacturer.service';
import { manufacturerProfile } from '../../../Manufacturer/Models/ManufacturerProfile/manufacturerProfile';

@Component({
  selector: 'app-manufacturer-detail',
  templateUrl: './manufacturer-detail.component.html',
  styleUrls: ['./manufacturer-detail.component.css']
})
export class ManufacturerDetailComponent implements OnInit {

  public _p: manufacturerProfile = new manufacturerProfile();
  private _id: number = 0;
  public isClaimDisabled: boolean = false;
  private _email :string ;

  // slider fields
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  constructor(
    private route: ActivatedRoute,
    private _router: Router,
    private _manufactureService: ManufacturerService,
    public dialog: MatDialog,
    private commonMethod: CommponMethod,
  ) { }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    this._id = Number(id);
    this.fetchData(id);
    this.initSlider();
  }

  fetchData(argProfileId: string) {
    this.commonMethod.showLoader();
    this._manufactureService.getManufacturerPublicProfileDetail(argProfileId).subscribe(res => {
      this.commonMethod.hideLoader();
      if (res.success) {
        this._p = res.data
        this._email = res.data.primaryEmail;
        console.log('email', this._email);
        this.initSliderImages(res.data.factoryImagesList);
      } else {
        this._router.navigate(["/main/dashboard"]);
      }
    },
    err => { },
    () => {
      this.commonMethod.hideLoader();
    });
  }

  private initSliderImages(images) {
    this.galleryImages = [];
    for (var i = 0; i < images.length; i++) {
      this.galleryImages.push({
        small: images[i].thumbnailUrl,
        medium: images[i].url,
        big: images[i].url,
        url: images[i].url
      })
    }
    // console.log('images =>>', this.galleryImages);
  }

  private initSlider() {
    this.galleryOptions = [
      {
        width: '100%',
        height: '465px',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide,
      },
      { "imageSize": "contain" },
      // max-width 800
      { "previewCloseOnClick": true, "previewCloseOnEsc": true },
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false,
      }
    ];
  }

  censorWord =  (str) => {
    return str[0] + "*".repeat(str.length - 2) + str.slice(-1);
 }
 
 censorEmail =  (email) => {
      var arr = email.split("@");
      return this.censorWord(arr[0]) + "@" + this.censorWord(arr[1]);
 }

  //#region Events
  onClaim() {
    // 
    if (this._id) {
      this.toggleClaimButton();
      this.commonMethod.showLoader();
      this._manufactureService.claimManufacturerPublicProfile(this._id).subscribe(
        res => {
          if (res.success) {
            var obsfucate_email = this.censorEmail(this._email);
            this.openDialog(obsfucate_email);
          }
        },
        err => { },
        () => {
          this.commonMethod.hideLoader();
        })
    }
  }
  //#endregion

  openDialog(_email:string): void {
    this.dialog.open(ManufacturerClaimSuccessDialog, {
      width: '440px',
      height: '225px',
      panelClass: 'thankyou-modalbox',
      data: {email: _email}   
    });
  }

  public countEstablishedYear(year: number): number {
    if (year) {
      return (new Date().getFullYear() - year);
    }
  }

  private toggleClaimButton() {
    if (!this.isClaimDisabled) {
      setTimeout(() => this.toggleClaimButton(), 1000 * 60);
    }
    this.isClaimDisabled = !this.isClaimDisabled;
  }

}

@Component({
  selector: 'manufacturer-claim-success-dialog',
  templateUrl: 'manufacturer-claim-success-dialog.html',
})
export class ManufacturerClaimSuccessDialog {

  constructor(
    public dialogRef: MatDialogRef<ManufacturerClaimSuccessDialog>,
     @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
