import { Component, ViewChild, Inject, OnInit } from '@angular/core';
import { ImageCropperComponent } from 'src/app/image-cropper/component/image-cropper.component';
import { ImageCroppedEvent } from 'src/app/image-cropper/interfaces';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'profilepic-uploader',
    templateUrl: './profilepic.component.html',
    styleUrls: ['./profilepic.component.css']
})
export class ProfilePicUploadComponent implements OnInit {
    imageChangedEvent: any = '';
    croppedImage: any = '';
    showCropper = false;
    event: any;
    constructor(
        private dialogRef: MatDialogRef<ProfilePicUploadComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any, ) { }
    @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;

    ngOnInit() {
        if (this.data) {
            //this.event = this.data['evt'];
            this.fileChangeEvent(this.data['evt']);
        }
    }
    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }
    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }
    cropAndUpload() {
        this.dialogRef.close({ success: true, image: this.croppedImage });
    }
    cancelPopup() {
        this.dialogRef.close({ success: false });
    }
    imageLoaded() {
        this.showCropper = true;
        console.log('Image loaded')
    }
    cropperReady() {
        console.log('Cropper ready')
    }
    loadImageFailed() {
        console.log('Load failed');
    }
    rotateLeft() {
        this.imageCropper.rotateLeft();
    }
    rotateRight() {
        this.imageCropper.rotateRight();
    }
    flipHorizontal() {
        this.imageCropper.flipHorizontal();
    }
    flipVertical() {
        this.imageCropper.flipVertical();
    }
}