import { Component, OnInit } from '@angular/core';
import { CommponMethod } from 'src/app/core/Helper/CommonMethods';
import { Router } from '@angular/router';
import { JwtService } from 'src/app/core/services/jwt.service';

@Component({
    selector: 'not-authorize',
    templateUrl: './notauthorize.component.html'
})
export class notAuthorizeComponent implements OnInit {
    //local fields
    public _role = null;
    public _userLoggedIn = null;
    constructor(private commonMethod: CommponMethod, private _route: Router, private _jwtService: JwtService) {

    }
    ngOnInit() {
        this.commonMethod.hideLoader();
        this.checkIfTokenExist();
        this.getRole();
    }
    public redirectToRoute() {
        if (this._userLoggedIn) {
            if (this._role === 'Inventor') {
                this._route.navigate(['/inventor', 'myProjects']);
            } else if (this._role === 'Manufacturer') {
                this._route.navigate(['/manufacturer', 'search-project']);
            } else if (this._role === 'Admin') {
                this._route.navigate(['/admin', 'adminDashboard']);
            }
        } else {
            this._route.navigate(['/main', 'dashboard']);
        }
    }
    private getRole() {
        const _result = this._jwtService.getTokenData();
        if (_result) {
            this._role = _result['role'];
        }
    }
    private checkIfTokenExist() {
        const result = this._jwtService.isTokenAvailable();
        if (result) {
            this._userLoggedIn = true;
        } else {
            this._userLoggedIn = false;
        }
    }
}