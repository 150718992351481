import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';

@Directive({
    selector: '[minLength][formControlName],[minLength][formControl],[minLength][ngModel]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MinLength, multi: true }]
})
export class MinLength implements Validator {
    @Input() minLength: number;

    validate(c: FormControl): { [key: string]: any } {
        if (c.value == null) {
            return;
        }
        if (c.value.length < this.minLength) {
            return { "minLength": true }
        }
        else {
            return null;
        }
    }
} 