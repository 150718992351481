import { Injectable, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, NgForm } from '@angular/forms';
import { JsonConvert, ValueCheckingMode } from "json2typescript";
import * as _ from 'lodash';
import { Observable, ReplaySubject } from 'rxjs';
//import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class GlobalService {
  public jsonConvert: JsonConvert = new JsonConvert();
  private browserName: string;
  public showLoader: boolean = false;
  private _notifier: NotifierService;

  constructor(
    private router: Router,
    private notifier: NotifierService,
    // private toaster: ToastrService,
    @Inject(DOCUMENT) private document: any
  ) {
    this.jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
    this.jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_OBJECT_NULL;
    this.browserName = this.getBrowserName().toLowerCase();
    this._notifier = notifier;
  }

  toggleLoader() {
    this.showLoader = !this.showLoader
  }

  addClassToBody(className) {
    this.document.body.classList.add(className);
  }

  removeClassFromBody(className) {
    this.document.body.classList.remove(className);
  }

  handleErrorMessage(msg) {
    if (!this.validateIsEmpty(msg)) {
      //this.toaster.error(msg, null, { timeOut: 3000 });
    }
    else {
      //this.toaster.error('Error occured', null, { timeOut: 3000 });
    }
  }

  handleSuccessMessage(msg) {
    if (!this.validateIsEmpty(msg)) {
      //this.toaster.success(msg, null, { timeOut: 3000 });
    }
  }

  handleInfoMessage(msg) {
    if (!this.validateIsEmpty(msg)) {
      //this.toaster.info(msg, null, { timeOut: 3000 });
    }
  }

  handleApiError(input) {
    if (!this.validateIsEmpty(input) && !this.validateIsEmpty(input.error) && !this.validateIsEmpty(input.error.Errors)) {
      for (let i = 0; i < input.error.Errors.length; i++) {
        this.handleErrorMessage(input.error.Errors[i].Message);
      }
    }
    else if (!this.validateIsEmpty(input) && !this.validateIsEmpty(input.error) && !this.validateIsEmpty(input.error.Message)) {
      this.handleErrorMessage(input.error.Message);
      if (input.error.Message.toString() == "Token Failed") {
        this.DeleteAuthToken();
      }
    }
    else if (!this.validateIsEmpty(input) && !this.validateIsEmpty(input.Errors)) {
      for (let i = 0; i < input.Errors.length; i++) {
        this.handleErrorMessage(input.Errors[i].Message);
      }
    }
    else if (!this.validateIsEmpty(input) && !this.validateIsEmpty(input.Message)) {
      this.handleErrorMessage(input.Message);
    }
    else {
      this.handleErrorMessage("Something Went Wrong");
    }
  }

  downloadFileWithURL(fileURL, fileName) {
    let a = document.createElement('a');
    a.setAttribute("style", "display:none");
    a.setAttribute("href", fileURL);
    a.setAttribute("download", fileName);
    document.body.appendChild(a);
    a.click();

    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(fileURL);
    }, 100);
  }

  downloadFileWithBlob(fileBlob, fileName) {
    let fileURL = URL.createObjectURL(fileBlob);
    let a = document.createElement('a');
    a.setAttribute("style", "display:none");
    a.setAttribute("href", fileURL);
    a.setAttribute("download", fileName);
    document.body.appendChild(a);
    a.click();

    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(fileURL);
    }, 100);
  }

  cloneData(data, defaultReturn: string = '') {
    if (!this.validateIsEmpty(data)) {
      return _.cloneDeep(data);
    }
    else {
      return defaultReturn;
    }
  }

  HasAuthToken(): boolean {
    if (localStorage.getItem("jwtToken")) {
      return true;
    }
    else {
      return false;
    }
  }

  DeleteAuthToken(): void {
    window.localStorage.clear();
    this.router.navigate(['/main', 'dashboard']);
  }

  // validateFormFields(formGroup: FormGroup) {
  //   let formErrors = [];
  //   Object.keys(formGroup.controls).forEach(field => {
  //     const control = formGroup.get(field);
  //     if (control instanceof FormControl) {
  //       if (control.invalid) {
  //         const errors = Object.keys(control.errors);
  //         for (let j = 0; j < errors.length; j++) {
  //           formErrors.push({ Field: field, Message: field + " " + errors[j] });
  //         }
  //       }
  //       control.markAsTouched({ onlySelf: true });
  //     }
  //     else if (control instanceof FormGroup) {
  //       this.validateFormFields(control);
  //     }
  //   });
  //   return formErrors;
  // }

  validateFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      }
      else if (control instanceof FormGroup) {
        this.validateFormFields(control);
      }
    });
  }

  containFormError(form: NgForm, ctrlName) {
    let formErrors = [];
    try {
      if (form.form.controls[ctrlName].invalid) {
        const errors = Object.keys(form.form.controls[ctrlName].errors);
        for (let i = 0; i < errors.length; i++) {
          formErrors.push(ctrlName + " " + errors[i]);
        }
      }
      return formErrors;
    }
    catch (error) {
      return formErrors;
    }
  }

  getActivatedRoute(): Observable<any> {
    return Observable.create((observer) => {
      this.router.events.subscribe((val) => {
        if (val instanceof NavigationEnd) {
          const route = val.urlAfterRedirects.split('/')[val.urlAfterRedirects.split('/').length - 1];
          observer.next(route);
        }
      });
    })
  }

  getPreciseAge(date): string {
    if (!this.validateIsEmpty(date)) {
      let currentDate = new Date();
      let dateOfBirth = new Date(date);
      let age: string = '';
      let year = currentDate.getFullYear() - dateOfBirth.getFullYear();
      let month = currentDate.getMonth() - dateOfBirth.getMonth();
      let currentDayDate = currentDate.getDate();
      let birthDayDate = dateOfBirth.getDate();

      if (month == 0 && (currentDayDate < birthDayDate)) {
        year = year - 1;
        month = 11;
      }

      else if (month < 0) {
        year = year - 1;
        month = 12 - Math.abs(month);
      }

      if (year != 0) {
        age = age + year.toString() + ' Years';
      }

      if (month != 0) {
        if (age != '') {
          age = age + ' ' + month.toString() + ' Months';
        }
        else {
          age = age + month.toString() + ' Months';
        }
      }

      return age;
    }
    else {
      return '';
    }
  }

  concatFnameLname(fname, lname) {
    if (!this.validateIsEmpty(fname) && !this.validateIsEmpty(lname)) {
      return fname.toString() + ' ' + lname.toString();
    }
    else if (!this.validateIsEmpty(fname)) {
      return fname.toString();
    }
    else {
      return '';
    }
  }

  concatAddress(address, city, zipcode, state, country) {
    let completeAddress = [address, city, zipcode, state, country];
    completeAddress = completeAddress.filter((obj) => {
      if (!this.validateIsEmpty(obj)) {
        return obj;
      }
    });
    return completeAddress.join(", ");
  }

  printNameWithPost(fname, lname, post) {
    let fullName: string = '';
    if (this.validateIsEmpty(fname)) {
      return '';
    }
    else {
      if (!this.validateIsEmpty(fname) && !this.validateIsEmpty(lname)) {
        fullName = fullName + fname + ' ' + lname;
      }
      else {
        fullName = fullName + fname;
      }
      if (this.validateIsEmpty(post)) {
        return fullName;
      }
      else {
        return [fullName, post].join(", ");
      }
    }
  }

  checkBrowserCompatibility(notAllowedBrowsers) {
    return notAllowedBrowsers.filter((browser) => {
      if (this.browserName.search(browser) !== -1) {
        return browser;
      }
    });
  }

  getBrowserName() {
    var ua = navigator.userAgent, tem,
      M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return 'IE ' + (tem[1] || '');
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join(' ');
  }

  upgradeDateByDays(date, counter) {
    let incomingDate: Date = _.cloneDeep(date);
    return new Date(incomingDate.setDate(incomingDate.getDate() + counter));
  }

  upgradeDateByMonths(date, counter) {
    let incomingDate: Date = _.cloneDeep(date);
    return new Date(incomingDate.setMonth(incomingDate.getMonth() + counter));
  }

  upgradeDateByYears(date, counter) {
    let incomingDate: Date = _.cloneDeep(date);
    return new Date(incomingDate.setFullYear(incomingDate.getFullYear() + counter));
  }

  downgradeDateByDays(date, counter) {
    let incomingDate: Date = _.cloneDeep(date);
    return new Date(incomingDate.setDate(incomingDate.getDate() - counter));
  }

  downgradeDateByMonths(date, counter) {
    let incomingDate: Date = _.cloneDeep(date);
    return new Date(incomingDate.setMonth(incomingDate.getMonth() - counter));
  }

  downgradeDateByYears(date, counter) {
    let incomingDate: Date = _.cloneDeep(date);
    return new Date(incomingDate.setFullYear(incomingDate.getFullYear() - counter));
  }

  convertTimeFormat(oldTimeFormat) {
    if (this.validateIsEmpty(oldTimeFormat)) {
      return;
    }
    else {
      let newTimeFormat = oldTimeFormat.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [oldTimeFormat];
      if (newTimeFormat.length > 1) {
        newTimeFormat = newTimeFormat.slice(1);
        newTimeFormat[5] = +newTimeFormat[0] < 12 ? ' am' : ' pm';
        newTimeFormat[0] = +newTimeFormat[0] % 12 || 12;
      }
      let array = Object.values(newTimeFormat);
      array.splice(3, 1);
      return array.join('');
    }
  }

  validateImageSize(file: File, minWidth, minHeight): Observable<any> {
    return Observable.create(observer => {
      let newImage: any = new Image();
      let fileReader: FileReader = new FileReader();
      fileReader.onloadend = function (loadEvent: any) {
        newImage.src = loadEvent.target.result;
        newImage.onload = function () {
          if (newImage.width >= minWidth && newImage.height >= minHeight) {
            observer.next({
              status: true,
              width: newImage.width,
              height: newImage.height,
              image: newImage
            });
          }
          else {
            observer.next({
              status: false,
            });
          }
        }
      }
      fileReader.readAsDataURL(file);
    })
  }
  checkImageSize(size: number): boolean {
    if (size) {
      let _imageSize = Math.round(size / (1024 * 1024))
      if (_imageSize <= 5) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }
  checkOnlyImageExtension(ext) {
    let extensions = ['jpg', 'png', 'jpeg'];
    if (ext) {
      if (extensions.indexOf(ext.toLowerCase()) > -1) {
        return true;
      }
      else {
        return false;
      }
    }
  }
  checkImageExtension(ext) {
    let extensions = ['jpg', 'png', 'jpeg', 'doc', 'pdf', 'docx'];
    if (ext) {
      if (extensions.indexOf(ext.toLowerCase()) > -1) {
        return true;
      }
      else {
        return false;
      }
    }
  }
  checkDocumentExtension(ext) {
    let extensions = ['doc', 'pdf', 'docx'];
    if (ext) {
      if (extensions.indexOf(ext.toLowerCase()) > -1) {
        return true;
      }
      else {
        return false;
      }
    }
  }
  dataURLtoBlob(dataURI): Observable<any> {
    return Observable.create((observer) => {
      var byteString = atob(dataURI.split(',')[1]);
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      var blob = new Blob([ab], { type: mimeString });
      observer.next({
        data: blob
      });
    });
  }
  imageThumbnail(reqObject): Observable<any> {
    return Observable.create(observer => {
      let originalWidthFull = reqObject.imageWidth;
      let originalHeightFull = reqObject.imageHeight;
      let originalWidthThumb = reqObject.imageWidth;
      let originalHeightThumb = reqObject.imageHeight;

      let ratioFull = originalHeightFull / originalWidthFull;
      let ratioThumb = originalHeightThumb / originalWidthThumb;

      if (originalWidthFull > reqObject.imageMaxFullWidth) {
        originalWidthFull = reqObject.imageMaxFullWidth;
        originalHeightFull = ratioFull * (reqObject.imageMaxFullWidth);
      }
      if (originalWidthThumb > reqObject.imageMaxThumbWidth) {
        originalWidthThumb = reqObject.imageMaxThumbWidth;
        originalHeightThumb = ratioThumb * (reqObject.imageMaxThumbWidth);
      }

      let canvas = document.createElement('canvas');
      let fullImageCompress = new Image();
      let thumbImageCompress = new Image();
      canvas.width = originalWidthFull;
      canvas.height = originalHeightFull;
      canvas.getContext("2d").drawImage(reqObject.image, 0, 0, originalWidthFull, originalHeightFull);
      fullImageCompress.src = canvas.toDataURL(reqObject.fileType, 1);

      canvas.width = originalWidthThumb;
      canvas.height = originalHeightThumb;
      canvas.getContext("2d").drawImage(reqObject.image, 0, 0, originalWidthThumb, originalHeightThumb);
      thumbImageCompress.src = canvas.toDataURL(reqObject.fileType, 1);

      observer.next({
        status: true,
        data: {
          fullImageCompress: fullImageCompress,
          thumbImageCompress: thumbImageCompress,
          width: originalWidthFull,
          height: originalHeightFull,
        }
      });
    })
  }
  getBase64(file): Observable<any> {
    return Observable.create(observer => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        observer.next({
          file: reader.result
        });
        reader.onerror = error => { }
      }
    });
  }
  validateAttachmentSize(file: File, minWidth, minHeight): Observable<any> {
    return Observable.create(observer => {
      let newImage: any = new Image();
      let fileReader: FileReader = new FileReader();
      fileReader.onloadend = function (loadEvent: any) {
        newImage.src = loadEvent.target.result;
        newImage.onload = function () {
          if (newImage.width >= minWidth && newImage.height >= minHeight) {
            observer.next({
              status: true,
              width: newImage.width,
              height: newImage.height,
              image: newImage
            });
          }
          else {
            observer.next({
              status: false,
            });
          }
        }
      }
      fileReader.readAsDataURL(file);
    })
  }
  compressImage(image, fileType, maxWidth, imageWidth, imageHeight): Observable<any> {
    return Observable.create(observer => {
      let originalWidth = imageWidth;
      let originalHeight = imageHeight;
      let ratio = originalHeight / originalWidth;
      if (originalWidth > maxWidth) {
        originalWidth = maxWidth;
        originalHeight = ratio * maxWidth;
      }
      let canvas = document.createElement('canvas');
      let compressedImage = new Image();
      canvas.width = originalWidth;
      canvas.height = originalHeight;
      canvas.getContext("2d").drawImage(image, 0, 0, originalWidth, originalHeight);
      compressedImage.src = canvas.toDataURL(fileType, 1);
      observer.next({
        status: true,
        data: {
          image: compressedImage,
          width: originalWidth,
          height: originalHeight,
        }
      });
    })
  }

  convertIntIntoFixedDecimal(number: number, range) {
    return number.toFixed(range);
  }

  // Mechanism By Which We Can Simply Solve Issue of (Selected Date - 1)
  // console.log(this.model.DOB, this.model.DOB.toISOString(), this.model.DOB.toUTCString());
  // this.model.DOB.setMinutes((this.model.DOB.getTimezoneOffset() * -1));
  // console.log(this.model.DOB, this.model.DOB.toISOString(), this.model.DOB.toUTCString());

  convertDateTimeToDate(date: any) {
    return date.setMinutes(date.getTimezoneOffset() * -1);
  }

  integralTimePart(time, type) {
    return parseInt(time.split(':')[type]);
  }

  timeSlotIntersection(o1_startTime, o1_endTime, o2_startTime, o2_endTime) {
    let o1_StartTime = this.integralTimePart(o1_startTime, 0) * 60 + this.integralTimePart(o1_startTime, 1);
    let o1_EndTime = this.integralTimePart(o1_endTime, 0) * 60 + this.integralTimePart(o1_endTime, 1);
    let o2_StartTime = this.integralTimePart(o2_startTime, 0) * 60 + this.integralTimePart(o2_startTime, 1);
    let o2_EndTime = this.integralTimePart(o2_endTime, 0) * 60 + this.integralTimePart(o2_endTime, 1);

    if ((o2_StartTime >= o1_StartTime && o2_StartTime < o1_EndTime) || (o2_EndTime > o1_StartTime && o2_EndTime <= o1_EndTime)) {
      return false;
    }
    else {
      return true;
    }
  }

  validateIsEmpty(input, list: Array<String> = []) {
    if ((typeof input).toLowerCase() == 'boolean') {
      return false;
    }
    else if (input != undefined && input != null && input != '') {
      if (input instanceof Date) {
        return false;
      }
      else if (Array.isArray(input) && input.length != 0) {
        return false;
      }
      else if ((typeof input).toLowerCase() == 'object') {
        let isedit = false;
        Object.keys(input).map(key => {
          if (list.indexOf(key.toString()) == -1 && (input[key] != undefined && input[key] != null && input[key] != '')) {
            isedit = true;
          }
        });
        if (isedit) {
          return false;
        }
        else {
          return true;
        }
      }
      else if ((typeof input).toLowerCase() == 'string' && input.length != 0) {
        return false;
      }
      else if ((typeof input).toLowerCase() == 'number') {
        return false;
      }
      else {
        return true;
      }
    }
    else {
      return true;
    }
  }

  checkObjectEquality(o1, o2, key = null) {
    let o1PropNames = Object.getOwnPropertyNames(o1);
    let o2PropNames = Object.getOwnPropertyNames(o2);
    if (o1PropNames.length !== o2PropNames.length) {
      return false;
    }
    for (let i = 0; i < o1PropNames.length; i++) {
      if (key != null && o1PropNames[i] == key) {
        continue;
      }
      if (o1[o1PropNames[i]] != o2[o1PropNames[i]]) {
        return false;
      }
    }
    return true;
  }

  getDefaultval() {
    return 'N/A';
  }

  validateIsEmptyAndSetDefVal(input) {
    if (this.validateIsEmpty(input)) {
      return this.getDefaultval();
    } else {
      return input;
    }
  }

  componentDestroyed(component: OnDestroy) {
    const oldNgOnDestroy = component.ngOnDestroy;
    const destroyed$ = new ReplaySubject<void>(1);
    component.ngOnDestroy = () => {
      oldNgOnDestroy.apply(component);
      destroyed$.next(undefined);
      destroyed$.complete();
    };
    return destroyed$;
  }

  formatPhoneNumber(phoneNumberString) {
    var match = phoneNumberString.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return + match[1] + '-' + match[2] + '-' + match[3];
    }
    return '';
  }

  formattedPhoneNumber(input) {
    if (Array.isArray(input) && input.length > 0) {
      let primeNumber = input.find(item => item.IsPrimary == 1);
      let formattedNumber = this.formatPhoneNumber(primeNumber.PhoneNumber);
      if (!this.validateIsEmpty(primeNumber) && !this.validateIsEmpty(formattedNumber)) {
        return "+" + primeNumber.PhoneCountryCode + '-' + formattedNumber;
      }
      else {
        return this.getDefaultval();
      }
    }
    else if ((typeof input).toLowerCase() == "object" && !this.validateIsEmpty(input) && !this.validateIsEmpty(this.formatPhoneNumber(input.PhoneNumber))) {
      return "+" + input.PhoneCountryCode + '-' + this.formatPhoneNumber(input.PhoneNumber);
    }
    else {
      return this.getDefaultval();
    }
  }
  public showNotification(type, message) {
    this._notifier.hideAll();
    this._notifier.notify(type, message);
  }
  /****** inventor project methods ********/
  public checkIfProjectInLocalStorage(): boolean {
    var exist = localStorage.getItem('inventorProject')
    if (exist) {
      return true;
    }
    else {
      return false;
    }
  }
  public removeProjectFromLocalStorage() {
    var exist = localStorage.getItem('inventorProject')
    if (exist) {
      localStorage.removeItem('inventorProject')
    }
  }
  /************ messages **************/

  public setMessageStorage(message): void {
    var exist = localStorage.getItem('messageStorage');
    if (exist) {
      localStorage.setItem("messageStorage", message);
    }
  }
  public getMessageStorage(): boolean {
    var exist = localStorage.getItem('messageStorage');
    if (exist) {
      return JSON.parse(localStorage.getItem('messageStorage'));
    }
    else {
      return false;
    }
  }
  public removeMessageFromStorage() {
    var exist = localStorage.getItem('messageStorage');
    if (exist) {
      localStorage.removeItem('messageStorage');
    }
  }
  public checkIfMessageExist(): boolean {
    var exist = localStorage.getItem('messageStorage')
    if (exist) {
      return true;
    }
    else {
      return false;
    }
  }
}
