import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { Observable } from 'rxjs';
import { JwtService } from '../../core/services/jwt.service';
import { HttpClient } from '@angular/common/http';
import { ResultModel } from '../../core/Models/Result-Model';
import { HelpModel } from '../Models/HelpModel';

@Injectable()
export class HelpService {

    constructor(private _apiService: ApiService, private http: HttpClient, private _jwtService: JwtService) { }

    public SendHelpEmail(model: HelpModel): Observable<ResultModel> {
        const path = '/Account/SendHelpEmail';
        return this._apiService.post(path, model);
    }

}
