import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';

@Directive({
    selector: '[ddlrequired][formControlName],[ddlrequired][formControl],[ddlrequired][ngModel]',
    providers: [{ provide: NG_VALIDATORS, useExisting: DropdownRequired, multi: true }]
})
export class DropdownRequired implements Validator {
    @Input()
    customMin: number;

    validate(c: FormControl): { [key: string]: any } {
        if (c.valid == false) {
            return { "ddlrequired": true };
        }
        else {
            return null;
        }
    }
} 