import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-itwork-for-inventor',
  templateUrl: './how-itwork-for-inventor.component.html',
  styleUrls: ['./how-itwork-for-inventor.component.css']
})
export class HowItworkForInventorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
