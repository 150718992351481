import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-useragreement',
  templateUrl: './useragreement.component.html',
})
export class UserAgreementComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scroll(0,0);
  }

}
