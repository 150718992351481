import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, LocationStrategy } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SignupComponent } from './components/signup/signup.component';
import { ForgotPswdComponent } from './components/forgot-pswd/forgot-pswd.component';
import { UiKitComponent } from './components/ui-kit/ui-kit.component';
import { LoginFormComponent } from './modals/login-form/login-form.component';
import { ConfirmationEmailComponent } from './components/confirmation-email/confirmation-email.component';
import { LinkedInAccessToken } from './components/linkedInAccessToken/linkedInAccessToken.component';
import { ResetPasswordComponent } from './components/password-reset/passwordReset.component';
import { PasswordResetSuccess } from './components/PasswordResetSuccess/passwordResetSuccess.component';
import { EmailConfirmSuccessfully } from './components/EmailConfirmSuccess/emailConfirmSuccessfully.component';
import { SubscriptionPlan } from './components/SubscriptionPlan/subscriptionPlan.component';
import { SignUpService } from './services/SignUpService';
import { ApiService } from './services/api.service';
import { JwtService } from './services/jwt.service';
import { HelperMethod } from './Helper/HelperMethod';
import { UsernameValidator } from './Helper/CheckEmailAvailability';
import { CommponMethod } from './Helper/CommonMethods';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CookieService } from 'angular2-cookie';
import { RouterModule } from '@angular/router';
import { CreateNewProjectComponent } from './components/CreateNewProject/CreateNewProject';
import { WelcomeScreenComponent } from './components/welcome-screen/welcome-screen.component';
import { DataService } from './services/data.service';
import { LinkedInSignUpComponent } from './components/LinkedInSignUpForManufacturer/LinkedInSignup.component';
import { CreditCardComponent } from './components/creditCard/creditCard.component';
import { ForgotPasswordSuccess } from './components/ForgotPasswordSuccess/forgotpassword.component';
import { NgxMaskModule } from 'ngx-mask';
import { ShowPercentageComponent } from './modals/ShowPercentage/ShowPercentage.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { GlobalService } from './services/global.service';
import { InventorService } from '../Inventor/Services/InventorService';
import { HelpFormComponent } from './modals/help-form/help-form.component';
import { ThankYouFeedbackComponent } from './modals/ThankYouFeedback/thankyou-feedback.component';
import { ManufacturerWorksComponent } from './modals/ManufacturerWorks/manufacturer-works.component';
import { HowItworkForInventorComponent } from './modals/how-itwork-for-inventor/how-itwork-for-inventor.component';
import { CreateRequestGuardService } from './routeGuards/my-guard.service';
import { ConfirmationDialogService } from '../Manufacturer/components/confirm-popup/confirm-popup.service';
import { ConfirmationDialogComponent } from '../Manufacturer/components/confirm-popup/confirm-popup.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MinLength } from './GlobalValidation/minLength';
import { InputTrimModule } from 'ng2-trim-directive';
import { whiteSpace } from './GlobalValidation/checkWhiteSpace';
import { UserAgreementComponent } from './components/useragreement/useragreement.component';
import { NoDataFoundComponent } from './components/404/404.component';
import { ManufacturersComponent } from './components/manufacturers/manufacturers.component';
import { ManufacturerService } from './services/manufacturer.service';
import { ManufacturerDetailComponent, ManufacturerClaimSuccessDialog } from './components/manufacturer-detail/manufacturer-detail.component';
import { NgxGalleryModule } from 'ngx-gallery';

@NgModule({
  declarations: [
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    SignupComponent,
    ConfirmationEmailComponent,
    ForgotPswdComponent,
    ResetPasswordComponent,
    EmailConfirmSuccessfully,
    PasswordResetSuccess,
    WelcomeScreenComponent,
    SubscriptionPlan,
    CreateNewProjectComponent,
    LinkedInAccessToken,
    UiKitComponent,
    LoginFormComponent,
    LinkedInSignUpComponent,
    CreditCardComponent,
    ForgotPasswordSuccess,
    ShowPercentageComponent,
    HowItworkForInventorComponent,
    ThankYouFeedbackComponent,
    HelpFormComponent,
    ManufacturerWorksComponent,
    ConfirmationDialogComponent,
    MinLength,
    whiteSpace,
    UserAgreementComponent,
    NoDataFoundComponent,
    ManufacturersComponent,
    ManufacturerDetailComponent,
    ManufacturerClaimSuccessDialog
  ],
  imports: [
    FormsModule,
    NgbModule,
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    HttpClientModule,
    NgxGalleryModule,
    RouterModule,
    InputTrimModule,
    NgxMaskModule.forRoot(),
    NgCircleProgressModule.forRoot({
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: '#78C000',
      innerStrokeColor: '#C7E596',
      animationDuration: 300,
    })
  ],
  exports: [
    HeaderComponent,
    DashboardComponent,
    FooterComponent,
    MinLength,
    ConfirmationDialogComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    SignUpService,
    ApiService,
    GlobalService,
    InventorService,
    DataService,
    JwtService,
    HelperMethod, 
    CreateRequestGuardService,
    UsernameValidator,
    //CookieService,
    CommponMethod,
    ConfirmationDialogService,
    ManufacturerService
  ],
  entryComponents: [
    LoginFormComponent,
    ShowPercentageComponent,
    HelpFormComponent,
    ThankYouFeedbackComponent,
    ManufacturerWorksComponent,
    ConfirmationDialogComponent,
    // ManufacturerDetailComponent,
    ManufacturerClaimSuccessDialog
  ]
})
export class CoreModule { }
