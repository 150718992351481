import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailConfirmationModel } from '../../Models/EmailConfirmationModel';
import { SignUpService } from '../../services/SignUpService';
import { NotifierService } from 'angular-notifier';
import { CommponMethod } from '../../Helper/CommonMethods';
import { GlobalService } from '../../services/global.service';
import { JwtService } from '../../services/jwt.service';
import { UserRole } from '../../Enum/UserRole';
import { emailreg } from '../../const';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-confirmation-email',
  templateUrl: './confirmation-email.component.html',
  styleUrls: ['./confirmation-email.component.css']
})
export class ConfirmationEmailComponent implements OnInit {
  //local fields
  public _emailConfirmationModel = new EmailConfirmationModel();
  public readonly _notifier: NotifierService;
  public _success: boolean = true;
  public emailAddress: string;
  public isInventor: boolean = false;
  public regexp = { email: emailreg };
  @ViewChild('Email') el: ElementRef;
  //constructor
  constructor(private activateRoute: ActivatedRoute, private globalService: GlobalService, private jwtService: JwtService, private _commonMetod: CommponMethod, private _route: Router, private _signUpService: SignUpService, private notifier: NotifierService) { }

  //hooks
  ngOnInit() {
    var params = this.activateRoute.snapshot.queryParams;
    this._emailConfirmationModel.Token = params.token;
    this._emailConfirmationModel.UserId = params.userId;
    this._emailConfirmationModel.ProjectId = params.projectId;
    this.EmailConfirmation();
  }
  public ResendConfirmationBtn(f: NgForm): void {
    let _this = this;
    this._commonMetod.showLoader();
    this._signUpService.ResendConfirmation(this._emailConfirmationModel.Email, this._emailConfirmationModel.ProjectId).subscribe(res => {
      if (res) {
        (<HTMLFormElement>document.getElementById("confirmationForm")).reset();
        _this._commonMetod.hideLoader();
        _this.globalService.showNotification("success", "Confirmation email sent successfully");
      }
      else {
        _this._commonMetod.hideLoader();
        _this.globalService.showNotification("error", "Error sending confirmation email");
      }

    }, (error) => {
      _this._commonMetod.hideLoader();
    });
  }

  public getUserRole() {
    var roleId = this.jwtService.getUserRole();
    if (UserRole.Inventor == roleId) {
      this.isInventor = true;
    }
    else {
      this.isInventor = false;
    }
  }
  //methods
  private EmailConfirmation(): void {
    let _this = this;
    this._commonMetod.showLoader();
    this._signUpService.EmailConfirmation(this._emailConfirmationModel).subscribe(result => {
      if (result.success) {
        _this._success = true;
        _this.notifier.notify('success', 'Email confirmed successfully');
        if (result.data && result.data.role == "Inventor") {
          this.jwtService.saveToken(result.data, result.data.rememberme === "true");
        }
        _this._route.navigate(['/main', 'emailConfirmSuccess']);
        //this.redirectToNextScreen(result.data);
      }
      else {
        this._commonMetod.showLoader();
        _this._success = false;
      }
      //redirect to dashboard
    }, (error) => {
      this._commonMetod.showLoader();
    });
  }
  public redirectToNextScreen(params) {
    const role = params["role"];
    const projectId = params["projectId"];
    if (role.toLowerCase() === 'inventor') {
      if (projectId) {
        this._route.navigate(['/inventor', 'newproject']);
      }
      else {
        this._route.navigate(['/inventor', 'myProjects']);
      }
    }
    else if (role.toLowerCase() === 'manufacturer') {
      this._route.navigate(["/manufacturer", "info"]);
    }
  }


}
