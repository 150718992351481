import { Component, OnInit } from '@angular/core';
import { SignUpService } from '../../services/SignUpService';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { Router } from '@angular/router';
import { CommponMethod } from '../../Helper/CommonMethods';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-forgot-pswd',
  templateUrl: './forgot-pswd.component.html',
  styleUrls: ['./forgot-pswd.component.css']
})
export class ForgotPswdComponent implements OnInit {

  /** local fields **/
  public _forgotPasswordForm: FormGroup;
  private _notifier: NotifierService;

  /** constructor **/
  constructor(private _signUpService: SignUpService,
    private _commonMethod: CommponMethod,
    private _router: Router,
    private _fb: FormBuilder,
    private globalService: GlobalService,
    private __notifier: NotifierService) {
    this._notifier = __notifier;
  }

  /** hooks **/
  ngOnInit() {
    this.initilizeForgotPasswordForm();
  }

  /** methods **/
  private initilizeForgotPasswordForm(): void {
    this._forgotPasswordForm = this._fb.group({
      Email: this._fb.control("", [Validators.required, Validators.email])
    })
  }

  public get f() { return this._forgotPasswordForm.controls; }

  public onSubmit(): void {
    if (this._forgotPasswordForm.valid) {
      this._commonMethod.showLoader();
      let _this = this;

      this._signUpService.ForgotPassword(this._forgotPasswordForm.value.Email).subscribe(result => {
        if (result.success) {
          // setTimeout(function () {
          //   _this._router.navigate(['/main', 'dashboard']);
          // }, 2000); passwordResetScreen
          setTimeout(function () {
            _this._router.navigate(['/main', 'passwordResetScreen']);
          }, 2000);
        }
        else {
          _this.globalService.showNotification("error", result.errorMessage);
        }

        _this._commonMethod.hideLoader();

      })
    }
  }
}
