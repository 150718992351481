import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { SignUpService } from '../../services/SignUpService';
import { CommponMethod } from '../../Helper/CommonMethods';
import { Router } from '@angular/router';
import { GlobalService } from '../../services/global.service';
import { JwtService } from '../../services/jwt.service';
import { UserRole } from '../../Enum/UserRole';

@Component({
    selector: 'email-confirm-successfully',
    templateUrl: './emailConfirmSuccessfully.component.html'
})
export class EmailConfirmSuccessfully {
    //local fields
    public _ShowEmailSection: boolean = false;
    public _forgotPasswordForm: FormGroup;
    private _notifier: NotifierService;
    public isInventor: boolean = false;
    public inProjectExist: boolean = false;
    public projectId: number = 0;

    /** constructor **/
    constructor(private _signUpService: SignUpService,
        private _commonMethod: CommponMethod,
        private _router: Router,
        private jwtService: JwtService,
        private _fb: FormBuilder,
        private globalService: GlobalService,
        private __notifier: NotifierService) {
        this._notifier = __notifier;
    }

    /** hooks **/
    ngOnInit() {
        var _result = this.jwtService.getTokenData();
        if (_result) {
            this.getUserData();
        }
        this.initilizeForgotPasswordForm();
    }
    public ShowEmailSection(): void {
        this._ShowEmailSection = !this._ShowEmailSection;
    }
    private initilizeForgotPasswordForm(): void {
        this._forgotPasswordForm = this._fb.group({
            Email: this._fb.control("", [Validators.required, Validators.email])
        })
    }
    public get f() { return this._forgotPasswordForm.controls; }
    public onSubmit(): void {
        if (this._forgotPasswordForm.valid) {
            this._commonMethod.showLoader();
            let _this = this;
            this._signUpService.ResendConfirmation(this._forgotPasswordForm.value.Email, null).subscribe(result => {
                if (result) {
                    _this.globalService.showNotification("success", "Confirmation email has been sent");
                    _this._ShowEmailSection = false;
                    setTimeout(function () {
                        _this._router.navigate(['/main', 'passwordResetScreen']);
                    }, 2000);
                }
                else {
                }

                _this._commonMethod.hideLoader();

            })
        }
    }

    public getUserData() {
        var _result = this.jwtService.getTokenData();
        if (_result) {
            let role = _result["role"];
            let projectId = _result["projectId"];
            if (role == "Inventor") {
                this.projectId = projectId;
                this.isInventor = true;
            }
            else {
                this.isInventor = false;
            }
        }
    }
    public redirectToDashBoard() {
        //delete token
        this.jwtService.deleteToken();
        this._router.navigate(['/main', 'dashboard'], { queryParams: { 'm': 'signin' } });
    }

}