import { Component, OnInit, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SignUpService } from 'src/app/core/services/SignUpService';
import { CommponMethod } from 'src/app/core/Helper/CommonMethods';
import { GlobalService } from 'src/app/core/services/global.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { emailreg, areacode } from 'src/app/core/const';

@Component({
    selector: 'app-update-primary-email',
    templateUrl: './updateprimaryemail.component.html'
})
export class UpdatePrimaryEmailComponent implements OnInit {
    //local fields
    public emailAddress: string;
    public countryCode: string;
    public phoneNumber: string;
    public regexp = { email: emailreg, areacode: areacode }
    //constructor
    constructor(private signUpService: SignUpService,
        private dialogRef: MatDialogRef<UpdatePrimaryEmailComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private commonMethod: CommponMethod, private globalService: GlobalService) { }

    ngOnInit() {
        if (this.data) {
            this.emailAddress = this.data['email'];
            this.countryCode = this.data['countryCode'];
            this.phoneNumber = this.data['phoneNumber'];
        }
    }

    //methods
    public UpdatePrimaryEmail(f: NgForm): void {
        let self = this;
        if (f.valid) {
            self.commonMethod.showLoader();
            self.signUpService.UpdatePrimaryEmail({ Email: self.emailAddress, CountryCode: self.countryCode, PhoneNumber: self.phoneNumber }).subscribe(result => {
                if (result.success) {
                    self.commonMethod.hideLoader();
                    self.closeModal(result.success, result.successMessage);
                }
                else {
                    self.closeModal(result.success, result.errorMessage);
                    self.commonMethod.hideLoader();
                }
            }, (error) => {
                self.commonMethod.hideLoader();
            })
        }
    }
    closeModal(status, message) {
        this.dialogRef.close({ status: status, email: this.emailAddress, phoneNumber: this.phoneNumber, countryCode: this.countryCode, message: message });
    }
}