import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef, Renderer2, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SignUpType } from '../../Models/SignUpType';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { SignUpModel } from '../../Models/SignUpModel';
import { SignUpService } from '../../services/SignUpService';
import { HelperMethod } from '../../Helper/HelperMethod';
import { MustMatch } from '../../Helper/CustomValidation';
import { NotifierService } from 'angular-notifier';
import { PasswordValidator } from '../../Helper/PasswordStrength';
import { GlobalService } from '../../services/global.service';
import { JwtService } from '../../services/jwt.service';
import { CommponMethod } from '../../Helper/CommonMethods';
import { DataService } from '../../services/data.service';
import { Subscription} from 'rxjs';
import { ManufacturerPlan } from '../../Models/ManufacturerPlans';
import { emailreg,areacode, charonly } from '../../const';

/** stripe credit card depedencies **/

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit, OnDestroy, AfterViewInit {

  /** local fields **/
  public planType: string = "month";
  public planId: number = 0;
  public _signUpType: SignUpType = new SignUpType();
  public isInventor: boolean;
  private _signUpModel: SignUpModel = new SignUpModel();
  public _signUpForm: FormGroup;
  public _formSubmited: boolean = false;
  private _notifier: NotifierService;
  public _showSection: string = "signUp";
  public token: string;
  public buttonText: string;
  public contentText: string;
  public _subscription: Subscription;
  public _subscriptionPlanId: Number = 0;
  public emaiReg: RegExp = emailreg;
  public regexp = { onlychar: charonly, areacode: areacode };
  public showConfirmationPopup: boolean = false;
  public projectid: boolean = false;
  private userId: number = 0;
  private userToken: string = '';
  public firstName: string = '';
  public lastName: string = '';
  public email: string = '';
  public phoneCode: string = '';
  public phoneNumber: string = '';
  /** stripe credit card fields **/
  card: any;
  creditCardError: string;
  stripe: any;
  shouldbe: boolean = false;
  cardHandler = this.onChange.bind(this);
  @ViewChild('cardElement') cardElement: ElementRef;
  /** constructor **/
  constructor(private activateRoute: ActivatedRoute,
    private _route: Router,
    private _fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private globalService: GlobalService,
    private _signupiService: SignUpService,
    private _helperMethod: HelperMethod,
    private __notifier: NotifierService,
    private _commonMethod: CommponMethod,
    private _messageService: DataService,
    private _jwtService: JwtService) {
    this._notifier = __notifier;
    let self = this;
    this.activatedRoute.queryParams.subscribe(params => {
      // console.log('params', params);
      if (params['userId']) {
        self.userId = params['userId'];
        self.userToken = params['token'];
      }
      if (params['_t']) {
        self.showConfirmationPopup = params["_t"];
        self.projectid = params["pid"];
      }
    });
    this._commonMethod.hideLoader();
  }

  /** life cycle hooks **/
  ngOnInit() {
    let self = this;
    window.scroll(0, 0);
    this._signUpType.Role = this.activateRoute.snapshot.paramMap.get('type');
    this.changeButtonText(this._signUpType.Role);
    this._signUpType.User = this.getUserType(this._signUpType.Role);
    this.initlizeSignUpForm();
    this._messageService.getMessage().subscribe(res => { });
    // this.getStripePlans();
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.hasOwnProperty('plan')) {
        let planId = parseInt(params['plan']);
        let planName = params['planType'];
        self.planType = planName;
        self.selectSubscriptionPlan(planId);
      }
    });
    if (this.userId) {
      this.getManufacturerData();
    }
  }

  private getManufacturerData() {
    // console.log('get manufacturer data');
    this._signupiService.getManufacturerDetails(this.userToken, this.userId).subscribe(res => {
      // console.log('res =>', res);
      if (res.success) {
        const { firstName, lastName, email, phoneNumber, primaryPhoneDialCode } = res.data;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.phoneNumber = phoneNumber;
        this.phoneCode = primaryPhoneDialCode;
      } else {
        this.globalService.showNotification("error", "Inavalid Token or Invalid User");
        this.userId = null;
        this.userToken = null;
        this._route.navigate(["/main", "signup", "Manufacturer"]);
      }
    });;
  }
  public togglePlans(type) {
    this.planType = type;
  }
  private onChange(error): void {
    if (error) {
      this.creditCardError = error.message;
      return;
    } else {
      this.creditCardError = null;
    }
    this.cd.detectChanges();
  }
  ngAfterViewInit() { }

  /** methods **/
  public redirectToRoute(route: string): void {
    if (route) {
      this._signUpType.Role = route;
      this.changeButtonText(route);
      let currentRole = this._helperMethod.RoleIdByName(this._signUpType.Role);
      this._signUpForm.patchValue({
        Role: currentRole
      });
      this._signUpType.User = this.getUserType(route);
      this._route.navigate(["/main", "signup", route]);
    }
  }

  private initlizeSignUpForm(): void {
    this._signUpForm = this._fb.group({
      FirstName: this._fb.control(this._signUpModel.FirstName, [Validators.required, Validators.pattern(this.regexp.onlychar)]),
      LastName: this._fb.control(this._signUpModel.LastName, [Validators.required, Validators.pattern(this.regexp.onlychar)]),
      Email: this._fb.control(this._signUpModel.Email, [Validators.required, Validators.pattern(this.emaiReg)], this.isEmailUnique.bind(this)),
      Referral: this._fb.control(this._signUpModel.Referral),
      Password: this._fb.control(this._signUpModel.Password, [Validators.required, Validators.minLength(8), this.noWhitespaceValidator, PasswordValidator.strong.bind(this)]),
      ConfirmPassword: this._fb.control(this._signUpModel.ConfirmPassword, [Validators.required]),
      PhoneNumber: this._fb.control(this._signUpModel.PhoneNumber, [Validators.required, Validators.minLength(10)]),
      CountryCode: this._fb.control(this._signUpModel.CountryCode, [Validators.required, Validators.pattern(this.regexp.areacode)]),
      Role: this._helperMethod.RoleIdByName(this._signUpType.Role),
      // cardholdername: this._fb.control(this._signUpModel.CardholderName),
      // MaskedCardNumber: this._fb.control(this._signUpModel.MaskedCardNumber),
      // CardExpiry: this._fb.control(this._signUpModel.MaskedCardNumber),
      // CardToken: this._fb.control(this._signUpModel.MaskedCardNumber),
      // CardType: this._fb.control(this._signUpModel.MaskedCardNumber),
      // SubscriptionId: this._fb.control(this._signUpModel.SubscriptionId),
      // ProjectId: this._fb.control(this._signUpModel.ProjectId),
    }, { validator: MustMatch('Password', 'ConfirmPassword') });
  }

  public get f() { return this._signUpForm.controls; }

  public selectSubscriptionPlan(subscriptionPlanId: number): boolean {
    let flag: boolean = false;
    if (subscriptionPlanId > 0) {
      this._subscriptionPlanId = subscriptionPlanId;
      this._signUpForm.patchValue({
        SubscriptionId: subscriptionPlanId
      });
      flag = true;
    }
    else {
      this.globalService.showNotification("error", "Please choose subscription plan");
    }
    return flag;
  }


  private changeButtonText(role:any): void {
    if (role == "Inventor" || role == "Manufacturer") {
      this.buttonText = "Create Account";
    }
    else {
      this.buttonText = "Next";
    }
  }

  public async _signUp() {
    this._formSubmited = true;
    this._commonMethod.showLoader();
    const _that = this;
    this._signupiService.SignUpManufacturer(this._signUpForm.value).subscribe(res=>{
      if (res.success) {
        this.globalService.showNotification("success", "Account created successfully");
        this._commonMethod.hideLoader();
        if (this.userId) {
          this._signupiService.SignIn(this._signUpForm.value.Email, this._signUpForm.value.Password, '').subscribe(result => {
            if (result.success) {
              _that.resetForm();
              this._jwtService.removeProjectFromToken();
              this._route.navigate(["/manufacturer/info"]);
            }
          });
        } else {
          _that._route.navigate(["/main", "successScreen"], { queryParams: { _flag: 'confirm' } });
        }
      }
      else {
        this._commonMethod.hideLoader();
        this.globalService.showNotification("error", res.errorMessage);
      }
    }, (error) => {
      this._commonMethod.hideLoader();
    });
  }

  public InventorSignUp() {
    this._formSubmited = true;
    if (this._signUpForm.valid) {
      this._commonMethod.showLoader();
      let _that = this;
      if (_that.projectid) {
        this._signUpForm.patchValue({
          ProjectId: _that.projectid
        })
      }
      this._signupiService.SignUpInventor(this._signUpForm.value).subscribe(res => {
        if (res.success) {
          this.globalService.showNotification("success", "Account created successfully");
          _that.resetForm();
          _that.showConfirmationPopup = false;
          _that._route.navigate(["/main", "successScreen"], { queryParams: { _flag: 'confirm', projectId: _that.projectid } });
        }
        else {
          this.globalService.showNotification("error", res.errorMessage);
        }
      });
      _that._commonMethod.hideLoader();


    }
  }
  public goToPlanScreen(): void {
    if (this._signUpForm.valid) {
      if (this._signUpType.Role == "Inventor") {
        this.InventorSignUp();
      }
      else if(this._signUpType.Role == "Manufacturer"){
        this._signUp();
      }
       else {
        this._showSection = "plans";
      }
    }
  }

  public goToSignUpScreen(): void {
    this._showSection = "signUp";
  }

  public goToCreditCardScreen(SubscriptionId) {
    let flag = this.selectSubscriptionPlan(SubscriptionId);
    if (flag == false) {
      return false;
    }

    if (!this.card) {
      this.card = elements.create('card', { hidePostalCode: true });
      this.card.mount(this.cardElement.nativeElement);
      this.card.addEventListener('change', this.cardHandler);
    }
    window.scroll(0, 0);
    this._showSection = "creditCard";
  }

  public SignUpWithLinkedIn(): void {
    let _role = this._helperMethod.RoleIdByName(this._signUpType.Role);
    if (_role > 0) {
      this._jwtService.saveUserRole(_role.toString());
    }
    if (this.projectid) {
      this._jwtService.saveProjectIdInStorage(this.projectid);
    }
    else {
      this._jwtService.removeProjectIdFromStorage();
    }
     window.location.href='https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86ld9gsm7481no&redirect_uri=https://manufacturefy.com/main/LinkedInToken&state=DCEeFWf45A53sdfKef468&scope=openid%20profile%20w_member_social%20email'
  }
  private getUserType(user: string): string {
    return user === 'Manufacturer' ? 'Manufacturefy' : 'Inventor';
  }

  private resetForm(): void {
    this._signUpForm.reset();
    for (var name in this._signUpForm.controls) {
      this._signUpForm.controls[name].setErrors(null);
    }
  }
  /** validation methods **/
  private noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  private isEmailUnique(control: FormControl) {
    if (!this.email) {
      const q = new Promise((resolve, reject) => {
        setTimeout(() => {
          this._signupiService.CheckEmailAvailability(control.value).subscribe((result) => {
            if (result.success)
              resolve(null);
            else
              resolve({ 'isEmailUnique': true });
          });
        }, 1000);
      });
      return q;
    } else {
      return new Promise((resolve, reject) => resolve(null));
    }
  }

  ngOnDestroy() {
    if (this.card != undefined) {
      this.card.removeEventListener('change', this.cardHandler);
      this.card.destroy();
    }
  }

  public durationNameById(id: number): string {
    return id === 1 ? 'Month' : 'Year';
  }
  public checkDivLength(length: number) {
    return length <= 3 ? 'singleDiv' : 'doubleDiv';
  }
}
