import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
declare var $: any;

@Component({
  selector: 'app-manufacturer-works',
  templateUrl: './manufacturer-works.component.html',
  styleUrls: ['./manufacturer-works.component.css']
})

export class ManufacturerWorksComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ManufacturerWorksComponent>) { }
  ngOnInit() { }
  closeManufacturerPopup() { this.dialogRef.close(); }

}
