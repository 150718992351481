import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { LoginFormComponent } from '../../modals/login-form/login-form.component';
import { HelpFormComponent } from '../../modals/help-form/help-form.component';
import { JwtService } from '../../services/jwt.service';
import { ThankYouFeedbackComponent } from '../../modals/ThankYouFeedback/thankyou-feedback.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

  public navbarOpen: boolean = false;
  fileNameDialogRef: MatDialogRef<LoginFormComponent>;
  constructor(public dialog: MatDialog, public jwtService: JwtService) { }
  public openLoginForm(): void {
    this.signOut();
    const dialogRef = this.dialog.open(LoginFormComponent, {
      width: '440px',
      height: '530px',
      panelClass: 'login-modalbox',
    });
  }
  ngOnInit() {
  }
  public signOut() {
    this.jwtService.deleteToken();
  }

  public toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  public openHelpForm(): void {
    const dialogRef = this.dialog.open(HelpFormComponent, {
      width: '440px',
      height: '530px',
      panelClass: 'login-modalbox'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result['data'] && result['data'] === true) {
        let self = this;
        const dialogRef = self.dialog.open(ThankYouFeedbackComponent, {
          width: '440px',
          height: '200px',
          panelClass: 'thankyou-modalbox'
        });
      }
    });
  }

}
