import { BehaviorSubject, Subject, Observable } from 'rxjs';

export class DataService {
    // local fields
    private subject = new BehaviorSubject<any>(0);
    private notification = new BehaviorSubject<any>(0);
    private event = new BehaviorSubject<any>(undefined);

    sendMessage(_data: any) {
        this.subject.next({ data: _data });
    }
    clearMessage() {
        this.subject.next(0);
    }
    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
    sendTransactionId(_data: any) {
        this.subject.next({ data: _data });
    }
    getTransactionId(): Observable<any> {
        return this.subject.asObservable();
    }
    InvokeMethod() {
        this.subject.next({ invoke: true });
    }
    refreshNoifications(_data: any) {
        this.notification.next({ data: _data });
    }
    reloadNoifications(): Observable<any> {
        return this.notification.asObservable();
    }

    //#region Event Data Service
    raiseEvent(argData: any) {
        this.event.next(argData);
    }
    captureEvent(): Observable<any> {
        return this.event;
    }
    //#endregion
}
