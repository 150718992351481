import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SignUpService } from '../../services/SignUpService';
import { LinkedInSignUpModel } from '../../Models/LinkedInSignupModel';
import { NotifierService } from 'angular-notifier';
import { GlobalService } from '../../services/global.service';
import { JwtService } from '../../services/jwt.service';
import { CommponMethod } from '../../Helper/CommonMethods';
import { DataService } from '../../services/data.service';

@Component({
    selector: 'linkedin-token',
    templateUrl: './linkedInAccessToken.component.html'
})
export class LinkedInAccessToken {
    //local fields
    private _linkedInSignUpModel = new LinkedInSignUpModel();
    private _notifier: NotifierService;

    //constructor
    constructor(private _activatedRoute: ActivatedRoute, private globalService: GlobalService, private _dataService: DataService, private _commonMetod: CommponMethod, private _jwtService: JwtService, private _route: Router, private _signupService: SignUpService, private __notifier: NotifierService) {
        this._notifier = __notifier;
        let _this = this;
        let _role = this._jwtService.retrieveUserRole();
        this._commonMetod.showLoader();
        this._activatedRoute.queryParams.subscribe(params => {
            if (params.error && params.error == "user_cancelled_login") {
                _this._route.navigate(["/main", "dashboard"]);
                return;
            }
            if (params.code) {
                _this._linkedInSignUpModel.code = params.code;
                _this._linkedInSignUpModel.Role = _role;
                _this._linkedInSignUpModel.ProviderKey = params.state;
                let projectId = this.getProjectIdFromStorage();
                if (projectId) {
                    _this._linkedInSignUpModel.ProjectId = projectId;
                }
                _this._jwtService.removeUserRole();
                _this.signupLinkedIn();
            }
            else {
                _this._route.navigate(["/main", "dashboard"]);
            }
        });
    }
    private getProjectIdFromStorage() {
        return this._jwtService.getProjectIdFromStorage();
    }
    //methods
    private signupLinkedIn(): void {
        let _this = this;
        this._commonMetod.showLoader();
        this._signupService.LinkedInSignUp(this._linkedInSignUpModel).subscribe(_result => {
            if (_result.success) { 
                _this.saveLinkedInDataInToken(_result.data);
                this._commonMetod.showLoader();
                if (_result.data.role == 'Inventor') {
                    var exist = _result.data["projectId"];
                    if (exist) {
                        _this._route.navigate(["/inventor", "newproject"]);
                    }
                    else {
                        _this._route.navigate(["/inventor", "myProjects"]);
                    }
                }
                else {
                    if (!_result.userExist) {
                        _this._dataService.sendMessage(_result.data);
                        _this._route.navigate(["/main", "LinkedInSignUp"]);
                        // go to manufacture signup with linked in
                    }
                    else {
                        _this._route.navigate(["/manufacturer", "info"]);
                    }
                }
            }
            else {
                this._commonMetod.showLoader();
                _this.globalService.showNotification("error", "Some problem occured while connecting Linkedin");
            }
            _this._commonMetod.hideLoader();
        }, (error) => {
            // console.log("Error occurred");
            // console.log(error);
            this._commonMetod.hideLoader();
        });
    }
    private saveLinkedInDataInToken(user: any): void {
        this._jwtService.saveToken(user, user.rememberme === "true");
    }
}