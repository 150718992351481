import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatButtonModule,
  MatAutocompleteModule,
  MatPaginatorModule,
  MatCheckboxModule,
  MatSelectModule,
  MatMenuModule,
  MatIconModule,
  MatBadgeModule,
  MatCardModule,
  MatInputModule,
  MatSliderModule,
  MatButtonToggleModule,
  MatDialogModule,
  MatTableModule,
  MatStepperModule,
  MatDatepickerModule,
} from '@angular/material';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  exports: [
    MatButtonModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatSelectModule,
    MatMenuModule,
    MatIconModule,
    MatBadgeModule,
    MatCardModule,
    MatInputModule,
    MatDatepickerModule,
    MatSliderModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatStepperModule,
    MatTableModule
  ]
})
export class MaterialModule { }
