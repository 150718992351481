import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SignUpService } from '../services/SignUpService';

@Injectable()
export class UsernameValidator {
    debouncer: any;

    constructor(public _signService: SignUpService) { }

    checkUsername(control: FormControl): any {
        clearTimeout(this.debouncer);
        return new Promise(resolve => {
            this.debouncer = setTimeout(() => {
                this._signService.CheckEmailAvailability(control.value).subscribe((res) => {
                    if (res.success) {
                        resolve(null);
                    }
                }, (err) => {
                    resolve({ 'usernameInUse': true });
                });
            }, 1000);
        });
    }
}