import { Component, ViewChild, ElementRef, ChangeDetectorRef, Renderer2, OnDestroy, OnInit, Input } from '@angular/core';
import { SignUpModel, CreditCardDetail } from '../../Models/SignUpModel';
import { FormGroup, FormBuilder, Validators, NgForm, FormGroupDirective } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { DataService } from '../../services/data.service';

@Component({
    selector: 'app-creditcard',
    templateUrl: './creditCard.component.html',
    styleUrls: ['./creditCard.component.css']
})
export class CreditCardComponent implements OnInit, OnDestroy {

    //local fields

    private _creditCardModel: CreditCardDetail = new CreditCardDetail();
    public _creditCardForm: FormGroup;
    private _notifier: NotifierService;
    public _showError: boolean = false;
    public error:string=null;
    @ViewChild('cardElement') cardElement: ElementRef;
    @Input() _creditCardDetail: CreditCardDetail;

    /** stripe credit card fields **/
    card: any;
    creditCardError: string;
    stripe: any;
    shouldbe: boolean = false;
    cardHandler = this.onChange.bind(this);

    /** constructor **/
    constructor(private cd: ChangeDetectorRef,
        private __notifier: NotifierService,
        private _fb: FormBuilder,
        private _messageService: DataService,
        private renderer: Renderer2) {
        this._notifier = __notifier;
    }

    /** methods **/
    ngOnInit() {
        this.initlizeCreditCardForm();
        this.createCreditCardTag();
    }
    private createCreditCardTag(): void {
        this.card = elements.create('card');
        this.card.mount(this.cardElement.nativeElement);
        this.card.addEventListener('change', this.cardHandler);
    }
    private initlizeCreditCardForm(): void {
        this._creditCardForm = this._fb.group({
            cardholdername: this._fb.control(this._creditCardModel.CardholderName, Validators.required),
            MaskedCardNumber: this._fb.control(this._creditCardModel.MaskedCardNumber, Validators.required),
            CardExpiry: this._fb.control(this._creditCardModel.MaskedCardNumber, Validators.required),
            CardToken: this._fb.control(this._creditCardModel.MaskedCardNumber, Validators.required),
            CardType: this._fb.control(this._creditCardModel.MaskedCardNumber, Validators.required),
        })
    }

    public get f() { return this._creditCardForm.controls; }

    private onChange({ error }): void {
        if (error) {
            this.error = error;
            this.creditCardError = error.message;
            return;
        } else {
            this.creditCardError = null;
        }
        this.cd.detectChanges();
    }
    public async validateCreditCard() {
        if (!this._creditCardForm.value.cardholdername) {
            this._showError = true;
            this.shouldbe = false
        }
        else {
            this._showError = false;
            const { token, error } = await stripe.createToken(this.card);
            if (error) {
                this.shouldbe = true
                this.error = error;
                this.creditCardError = error.message;
                return;
            }
            else {
                this._creditCardForm.patchValue({
                    MaskedCardNumber: "**** **** **** " + token.card.last4,
                    CardExpiry: `${token.card.exp_month}` + "/" + `${token.card.exp_year}`,
                    CardToken: token.id,
                    CardType: token.card.brand
                });
                this._creditCardDetail = this._creditCardForm.value;
            }
        }
    }

    /** hooks **/
    ngOnDestroy() {
        if (this.card != undefined) {
            this.card.removeEventListener('change', this.cardHandler);
            this.card.destroy();
        }
    }
}