import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
declare var $: any;

@Component({
  selector: 'app-thankyou-help',
  templateUrl: './thankYou-help.component.html',
  styleUrls: ['./thankYou-help.component.css']
})

export class ThankYouHelpComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ThankYouHelpComponent>) { }
  ngOnInit() { }
  clearFeedbackdata() { this.dialogRef.close(); }

}
