import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { JwtService } from '../../../core/services/jwt.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommponMethod } from 'src/app/core/Helper/CommonMethods';
import { manufacturerService } from 'src/app/Manufacturer/Services/ManufacturerService';
import { ManufacturerNotification } from 'src/app/Manufacturer/Models/ManufacturerNotification';
import * as moment from 'moment';
import { DataService } from 'src/app/core/services/data.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material';
import { HelpComponent } from '../../modals/help/help.component';
import { ThankYouHelpComponent } from '../../modals/thankYou-help/thankyou-help.component';

@Component({
  selector: 'app-logged-header',
  templateUrl: './logged-header.component.html',
  styleUrls: ['./logged-header.component.css']
})

export class LoggedHeaderComponent implements OnInit, OnDestroy {

  // local fields
  public isLinear = false;
  public _name = '';
  public newMessage = '!';
  public _role = null;
  public profilePic: string = null;
  public isExpired: boolean = false;
  public _userLoggedIn = false;
  public _notification: Array<ManufacturerNotification> = new Array<ManufacturerNotification>();
  public NotificationCount = 0;
  public projectId = 0;
  public notificationType: number;
  public unreadMessageCount = 0;
  public subscription: Subscription;

  constructor(private _jwtService: JwtService,
    private _activatedRoute: ActivatedRoute,
    private commonMethod: CommponMethod,
    private dialog: MatDialog,
    private dataService: DataService,
    private _manufacturerService: manufacturerService,
    private _route: Router) {

    const self = this;
    this.subscription = self.dataService.getMessage().subscribe(response => {
      if (response) {
        if (response.data['totalCount']) {
          self.unreadMessageCount = (self.unreadMessageCount - parseInt(response.data['totalCount']));
          self.NotificationCount = (self.NotificationCount - parseInt(response.data['totalCount']));
        }
        else if (response.data['unReadMsg'] > -1) {
          self.unreadMessageCount = (self.unreadMessageCount - parseInt(response.data['unReadMsg']));
        }
        else if (response.data.hasOwnProperty('profilePic')) {
          self.profilePic = response.data.profilePic;
        }
      }
    });

    this.subscription = self.dataService.reloadNoifications().subscribe(response => {
      if (response.data) {
        if (response.data['refreshNotification'] === 'true') {
          this.getManufacturerNotificationsCount();
        }
      }
    });

  }

  // hooks
  ngOnInit() {
    const _result = this._jwtService.getTokenData();
    if (_result) {
      this._name = _result['name'];
      this._role = _result['role'];
      this.profilePic = _result['profileImage'];
      this.isExpired = _result['isExpired'];

    }
    this.checkIfTokenExist();
    if (this._role === 'Manufacturer') {
      this.getManufacturerNotificationsCount();
    }
    if (this._role === 'Manufacturer' || this._role === 'Inventor') {
      this.getUnreadMessageCount();
    }
  }

  public signOut() {
    this.removeFiltersFromLocal();
    this._jwtService.deleteToken();
  }

  private checkIfTokenExist() {
    const result = this._jwtService.isTokenAvailable();
    if (result) {
      this._userLoggedIn = true;
    } else {
      this._userLoggedIn = false;
    }
  }

  public redirectToRoute() {
    if (this._userLoggedIn) {
      if (this._role === 'Inventor') {
        this._route.navigate(['/inventor', 'myProjects']);
      } else if (this._role === 'Manufacturer') {
        this._route.navigate(['/manufacturer', 'search-project']);
      } else if (this._role === 'Admin') {
        this._route.navigate(['/admin', 'adminDashboard']);
      }
    } else {
      this._route.navigate(['/main', 'dashboard']);
    }
  }

  public getDate(_date: Date) {
    const _tempDate = moment.utc(_date).toDate();
    const date1 = moment(_tempDate);
    return date1;
  }

  private getManufacturerNotificationsCount() {
    const _that = this;
    this._manufacturerService.getManufacturerNotificationsCount().subscribe(response => {
      if (response.success && response.data) {
        _that._notification = response.data;
        _that.NotificationCount = response.data[0].notificationCount;
      } else {
        _that.NotificationCount = 0;
        _that._notification = null;
      }
    });
  }

  private getUnreadMessageCount() {
    const _that = this;
    this._manufacturerService.getUnreadMessageCount().subscribe(response => {
      if (response.success) {
        _that.unreadMessageCount = response.data;
      }
    });
  }

  public updateNotification(notificationId, projectId, notificationType) {
    this._manufacturerService.updateReadNotification(notificationId).subscribe(response => {
      if (response) {
        if (notificationType === 1) {
          this._route.navigateByUrl('/DummyComponent', { skipLocationChange: true }).then(() => {
            this._route.navigate(['/main', 'inbox'], { queryParams: { 'pid': projectId, 'sid': notificationId } });
          });
        } else {
          this._route.navigateByUrl('/DummyComponent', { skipLocationChange: true }).then(() => {
            this._route.navigate(['/manufacturer', 'project-detail', projectId]);
          });
        }
      }
    });

  }

  private removeFiltersFromLocal() {
    const localData = localStorage.getItem('filters');
    const manufacturerFilters = localStorage.getItem('manufacturerSearchFilters');
    if (localData) {
      localStorage.removeItem('filters');
    }
    if (manufacturerFilters) {
      localStorage.removeItem('manufacturerSearchFilters');
    }
  }

  public openHelpPopup() {
    const dialogRef = this.dialog.open(HelpComponent, {
      width: '440px',
      height: '530px',
      panelClass: 'login-modalbox'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result['data'] && result['data'] === true) {
        const self = this;
        self.dialog.open(ThankYouHelpComponent, {
          width: '440px',
          height: '200px',
          panelClass: 'thankyou-modalbox'
        });
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
