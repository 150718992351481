import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { LoggedHeaderComponent } from './components/logged-header/logged-header.component';
import { RouterModule } from '@angular/router';
import { NewMessageComponent } from './components/inbox/NewMessage/NewMessage.component';
import { InboxComponent } from './components/inbox/inbox.component';
import { messageService } from './services/messageService';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DropdownRequired } from './CustomValidation/DropdownRequired';
import { manufacturerService } from '../Manufacturer/Services/ManufacturerService';
import { GlobalModule } from '../global.module';
import { notAuthorizeComponent } from './components/notAuthorize/notauthorize.component';
import { CoreModule } from '../core/core.module';
import { NoProjectFoundComponent } from './components/noProjectFound/noProjectFound.component';
import { UpdatePrimaryEmailComponent } from '../Inventor/components/UpdatePrimaryEmail/updateprimaryemail.component';
import { NgxMaskModule } from 'ngx-mask';
import { CustomMinDirective } from '../Manufacturer/CustomValidations/MinimumPhoneLength';
import { ImageCropperModule } from '../image-cropper/image-cropper.module';
import { ProfilePicUploadComponent } from './components/ProfilePictureUpload/profilepic.component';
import { HelpComponent } from './modals/help/help.component';
import { ThankYouHelpComponent } from './modals/thankYou-help/thankyou-help.component';
import { HelpService } from './services/HelpService';
import { InputTrimModule } from 'ng2-trim-directive';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
@NgModule({
  declarations: [
    LoggedHeaderComponent,
    InboxComponent,
    NewMessageComponent,
    DropdownRequired,
    notAuthorizeComponent,
    NoProjectFoundComponent,
    UpdatePrimaryEmailComponent,
    CustomMinDirective,
    ProfilePicUploadComponent,
    HelpComponent,
    ThankYouHelpComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
  ],
  imports: [
    CoreModule,
    CommonModule,
    MaterialModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    GlobalModule,
    NgxMaskModule.forRoot(),
    ImageCropperModule,
    InputTrimModule,
  ],
  exports: [
    LoggedHeaderComponent,
    CustomMinDirective,
    NgxMaskModule,
    ImageCropperModule,
  ],
  providers: [messageService, manufacturerService, HelpService],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  entryComponents: [UpdatePrimaryEmailComponent, ProfilePicUploadComponent, HelpComponent, ThankYouHelpComponent ],
})
export class SharedModule { }
