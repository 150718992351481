import { Component, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { LoginFormComponent } from '../login-form/login-form.component';
import { DataService } from '../../services/data.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'showPercentage',
    templateUrl: "./ShowPercentage.component.html"
})
export class ShowPercentageComponent implements OnDestroy {
    //local fields
    public per: number = 0;
    public message: string = "";
    private subscription: Subscription;
    //constructor
    constructor(public dialog: MatDialog,
        private dataService: DataService,
        private _dialogRef: MatDialogRef<ShowPercentageComponent>) {
        this.subscription = this.dataService.getMessage().subscribe(p => {
            this.per = p.data;
        })
    }


    /** methods **/
    public closeModal(): void {
        this._dialogRef.close();
    }
    ngOnDestroy() {
        this.per = 0;
        this.subscription.unsubscribe();
    }
}