import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { HelpModel } from '../../Models/HelpModel';
import { CommponMethod } from '../../Helper/CommonMethods';
import { ThankYouFeedbackComponent } from '../ThankYouFeedback/thankyou-feedback.component';
import { SignUpService } from '../../services/SignUpService';
import { GlobalService } from '../../services/global.service';
import { onlycharandspace, emailreg } from '../../const';
declare var $: any;

@Component({
  selector: 'app-help-form',
  templateUrl: './help-form.component.html',
  styleUrls: ['./help-form.component.css']
})
export class HelpFormComponent implements OnInit {

  public _helpForm: FormGroup;
  public _formSubmited: boolean;
  private _helpModel = new HelpModel();
  public regexp = { onlychar: onlycharandspace, emailreg: emailreg };
  constructor(
    private _dialogRef: MatDialogRef<HelpFormComponent>,
    private _commonMethod: CommponMethod,
    private dialog: MatDialog,
    private _fb: FormBuilder,
    private globalService: GlobalService,
    private _signUpService: SignUpService) { }

  ngOnInit() {
    this.initilizeHelpForm();
  }

  public get f() { return this._helpForm.controls; }

  private initilizeHelpForm(): void {
    this._helpForm = this._fb.group({
      name: this._fb.control(this._helpModel.name, [Validators.required, Validators.pattern(this.regexp.onlychar)]),
      email: this._fb.control(this._helpModel.email, [Validators.required, Validators.pattern(this.regexp.emailreg)]),
      query: this._fb.control(this._helpModel.query, [Validators.required])
    });
  }

  submitQuery(): void {
    let self = this;
    self._formSubmited = true;
    const _signInParams = this._helpForm.value;
    if (self._helpForm.valid) {
      self._commonMethod.showLoader();
      self._signUpService.SendHelpEmail(self._helpForm.value).subscribe(response => {
        if (response.success) {
          self._dialogRef.close({ data: true });
          self._commonMethod.hideLoader();
        } else {
          self._dialogRef.close({ data: false });
          self.globalService.showNotification('error', 'Something went wrong');
          self._commonMethod.hideLoader();
        }
      }, (error) => {
        self._dialogRef.close({ data: false });
        self.globalService.showNotification('error', 'Something went wrong');
        self._commonMethod.hideLoader();
      });
      self._commonMethod.hideLoader();
    }
  }

}
