import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { ResultModel } from '../Models/Result-Model';
import { Injectable } from '@angular/core';

@Injectable()
export class ManufacturerService {

  constructor(private _apiService: ApiService) { }

  public getUnclaimedManufacturers(PageIndex: number, PageSize: number, SearchParam: string = ""): Observable<ResultModel> {
    var path = "/Manufacturer/GetUnclaimedManufacturerList";
    var postData = { PageIndex, PageSize, SearchParam };
    return this._apiService.post<ResultModel>(path, postData);
  }

  public getManufacturerPublicProfileDetail(argId): Observable<ResultModel> {
    var path = `/Manufacturer/GetManufacturerPublicProfileDetail?argProfileId=${argId}`;
    return this._apiService.get<ResultModel>(path);
  }

  public claimManufacturerPublicProfile(_id: number) {
    const path = `/Account/ClaimPublicProfile?argModelId=${Number(_id)}`;
    return this._apiService.post<ResultModel>(path);
  }
  
  public getManufacturerSignupData(_id: number, _token: string) {
    const path = `/Account/ClaimPublicProfile?id=${Number(_id)}&token=${_token}`;
    return this._apiService.post<ResultModel>(path);
  }
}
