import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';

@Directive({
    selector: '[whiteSpace][formControlName],[whiteSpace][formControl],[whiteSpace][ngModel]',
    providers: [{ provide: NG_VALIDATORS, useExisting: whiteSpace, multi: true }]
})
export class whiteSpace implements Validator {
    @Input() whiteSpace: number;

    validate(c: FormControl): { [key: string]: any } {
        if (!c.value.trim()) {
            return { "whiteSpace": true }
        }
        else {
            return null;
        }
    }
} 