import { Component, OnInit } from '@angular/core';
export interface type {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-ui-kit',
  templateUrl: './ui-kit.component.html',
  styleUrls: ['./ui-kit.component.css']
})
export class UiKitComponent implements OnInit {
  public isLinear: boolean = false;
  projects: type[] = [
    { value: 'Ptype-0', viewValue: 'Ptype 1' },
    { value: 'Ptype-1', viewValue: 'Ptype 2' },
    { value: 'Ptype-2', viewValue: 'Ptype 3' }
  ];

  ngOnInit() {
  }

}
