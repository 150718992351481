import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ManufacturerService } from '../../services/manufacturer.service';

@Component({
  selector: 'app-manufacturers',
  templateUrl: './manufacturers.component.html',
  styleUrls: ['./manufacturers.component.css']
})
export class ManufacturersComponent implements OnInit, OnDestroy {

  value = 'Clear me';

  private sub: Subscription = new Subscription();
  public manufacturers: Array<{}>;
  public pagingModel = { page: 1, pageSize: 10, totalRecords: 0 };
  public search: string= "";
  constructor(
    private manufacturerService: ManufacturerService
  ) { }

  ngOnInit() {
    this.getTrustedManufecturers(1, this.pagingModel.pageSize);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private getTrustedManufecturers(page, pageSize, search = '') {
    // console.log('page, pageSize, search', page, pageSize, search);
    this.sub = this.manufacturerService.getUnclaimedManufacturers(page, pageSize, search)
      .subscribe(resData => {
        // console.log('data =>>', resData);
        if (resData.success) {
          this.manufacturers = resData.data
          if (resData.data && resData.data.length) {
            this.pagingModel.totalRecords = resData.data[0].totalRecords;
          }
        } else {
          this.manufacturers = [];
          this.pagingModel.totalRecords = 0;
        }
      });
  }

  public changePage(event) {
    // console.log(event);
    if (event.previousPageIndex === event.pageIndex && this.pagingModel.pageSize !== event.pageSize) {
      this.pagingModel.pageSize = event.pageSize;
      this.pagingModel.page = 1;
      this.getTrustedManufecturers(1, event.pageSize, this.search);
    } else {
      this.pagingModel.pageSize = event.pageSize;
      this.pagingModel.page = event.pageIndex + 1;
      this.getTrustedManufecturers(event.pageIndex + 1, event.pageSize, this.search);
    }
    return event;
  }

  public onSearchChange(search) {
    // console.log('search =>>', search);
    if (search && search.trim()) {
      this.search = search;
      this.pagingModel.page = 1;
      this.getTrustedManufecturers(1, this.pagingModel.pageSize, search);
    } else {
      this.pagingModel.page = 1;
      this.getTrustedManufecturers(1, this.pagingModel.pageSize);
    }
  }

  public onClearSearch() {
    this.search = '';
    this.getTrustedManufecturers(1, this.pagingModel.pageSize);
  }

}
