import { OnInit, Component } from '@angular/core';

@Component({
    selector: 'new-message',
    templateUrl: './NewMessage.component.html',
    styleUrls: ['./NewMessage.component.css']
})
export class NewMessageComponent implements OnInit {
    //fields

    //constructor
    constructor() { }

    //methods

    ngOnInit() { }

    callMe() {
        
    }
}