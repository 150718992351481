import { Component, OnInit } from '@angular/core';
import { JwtService } from 'src/app/core/services/jwt.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html', 
  styleUrls: ['./terms-and-conditions.component.css']
})
export class TermsAndConditionsComponent implements OnInit {

  //local fields
  public isLogedIn: boolean = false;
  constructor(private jwtService: JwtService) { }

  ngOnInit() {
    this.isLogedIn = this.jwtService.isTokenAvailable() > 0;
      window.scroll(0, 0);
  }

}
