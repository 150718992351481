import { Component } from '@angular/core';
import { templateJitUrl } from '@angular/compiler';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { SignUpService } from '../../services/SignUpService';
import { CommponMethod } from '../../Helper/CommonMethods';
import { Router } from '@angular/router';
import { GlobalService } from '../../services/global.service';

@Component({
    selector: 'app-password-success',
    templateUrl: "./forgotpassword.component.html",
    styleUrls: ['./forgotpassword.component.css']

})
export class ForgotPasswordSuccess {
    //local fields
    public _ShowEmailSection: boolean = false;
    public _forgotPasswordForm: FormGroup;
    private _notifier: NotifierService;

    /** constructor **/
    constructor(private _signUpService: SignUpService,
        private _commonMethod: CommponMethod,
        private _router: Router,
        private _fb: FormBuilder,
        private globalService: GlobalService,
        private __notifier: NotifierService) {
        this._notifier = __notifier;
    }
    /** hooks **/
    ngOnInit() {
        this.initilizeForgotPasswordForm();
    }
    //methods

    public ShowEmailSection(): void {
        this._ShowEmailSection = !this._ShowEmailSection;
    }
    private initilizeForgotPasswordForm(): void {
        this._forgotPasswordForm = this._fb.group({
            Email: this._fb.control("", [Validators.required, Validators.email])
        })
    }
    public get f() { return this._forgotPasswordForm.controls; }

    public onSubmit(): void {
        if (this._forgotPasswordForm.valid) {
            this._commonMethod.showLoader();
            let _this = this;

            this._signUpService.ForgotPassword(this._forgotPasswordForm.value.Email).subscribe(result => {
                if (result.success) {
                    _this.globalService.showNotification("success", "Reset password email has been sent");
                    _this._ShowEmailSection = false;
                    setTimeout(function () {
                        _this._router.navigate(['/main', 'passwordResetScreen']);
                    }, 2000);
                }
                else {
                    _this.globalService.showNotification("error", result.errorMessage);
                }

                _this._commonMethod.hideLoader();

            })
        }
    }
}