import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { Observable } from 'rxjs';
import { ResultModel } from '../../core/Models/Result-Model';
import { ContactInformation } from '../Models/ManufacturerContactInfomation';
import { ManufacturerContactInformation } from '../components/manufacturer-info/ContactInformation/ContactInformation.component';
import { JwtService } from '../../core/services/jwt.service';
import { FactoryInformation } from '../Models/ManufacturerFactoryInformation';
import { ManufacturerOwnerModel } from '../Models/ManufacturerOwnerModel';
import { searchProject } from '../Models/ProjectSearch/SearchProduct';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { ProjectQuoteSM } from 'src/app/Manufacturer/Models/ProjectDetail/ProjectQuoteSM';
import { ProjectQuoteDetail } from '../Models/ProjectDetail/ProjectQuote';
import { changePassword } from '../Models/ManufacturerAccountSetting/changePassword';
import { accountSetting } from '../Models/ManufacturerAccountSetting/accountSetting';
import { creditCardDetail } from '../Models/ManufacturerAccountSetting/creditCardDetail';
import { FileUploadModel } from '../Models/FileUploadModel';

@Injectable()
export class manufacturerService {

    constructor(private _apiService: ApiService, private http: HttpClient, private _jwtService: JwtService) { }

    public getCountries(): Observable<ResultModel> {
        const path = '/Manufacturer/CountriesList';
        return this._apiService.get<ResultModel>(path);
    }
    public getStateByCountryId(countryId: number): Observable<ResultModel> {
        const path = '/Manufacturer/StatesListByCountryId?CountryId=' + countryId;
        return this._apiService.get(path);
    }
    public saveManufacturerContact(_model: ContactInformation): Observable<ResultModel> {
        //const headers = new Headers();
        const path = '/Manufacturer/SaveManufacturerContact';
        return this._apiService.post(path, _model);
    }
    public saveManufacturerFactory(_model: FactoryInformation): Observable<ResultModel> {
        //const headers = new Headers();
        const path = '/Manufacturer/SaveManufacturerFactoryInfo';
        return this._apiService.post(path, _model);
    }
    public saveManufacturerOwner(_model: ManufacturerOwnerModel): Observable<ResultModel> {
        //const headers = new Headers();
        const path = '/Manufacturer/SaveManufacturerOwner';
        return this._apiService.post(path, _model);
    }
    public uploadImage(model: any): Observable<ResultModel> {
        //const headers = new Headers();
        const path = '/Common/UploadAttachment';
        return this._apiService.post(path, model);
    }
    public getManufacturerInitialData(): Observable<ResultModel> {
        const path = '/Manufacturer/GetManufacturerInitialData';
        return this._apiService.get<ResultModel>(path);
    }
    public getManufacturerFactoryDetail() {
        const path = '/Manufacturer/GetManufacturerFacturerDetail';
        return this._apiService.get<ResultModel>(path);
    }
    public deleteImage(model: any): Observable<ResultModel> {
        //const headers = new Headers();
        const path = '/Common/DeleteAttachment';
        return this._apiService.post(path, model);
    }
    public getContactInfo() {
        const path = '/Manufacturer/GetManufacturerContact';
        return this._apiService.get<ResultModel>(path);
    }
    public getManufacturerOwnersDetail() {
        const path = '/Manufacturer/GetManufacturerOwnersDetail';
        return this._apiService.get<ResultModel>(path);
    }
    public getManufacturerVerification() {
        const path = '/Manufacturer/GetManufacturerVerification';
        return this._apiService.get<ResultModel>(path);
    }
    public getProjectSearchInidialData() {
        const path = '/Manufacturer/GetProjectSearchInitialData';
        return this._apiService.get<ResultModel>(path);
    }
    public searchProjects(_model: searchProject): Observable<ResultModel> {
        //const headers = new Headers();
        const path = '/Manufacturer/SearchProject';
        return this._apiService.post(path, _model);
    }
    public getManufacturerFactoryEstablishedYear(): Observable<ResultModel> {
        //const headers = new Headers();
        const path = '/Manufacturer/GetManufacturerFactoryEstablishedYear';
        return this._apiService.get(path);
    }
    public downloadProjectAttachments(projectId) {
        return this.http.get(`${environment.apiUrl}` + '/Common/GetZip?projectId=' + projectId, {
            responseType: 'arraybuffer',
            reportProgress: true
        });
    }
    public downloadFile(projectId) {
        return new HttpRequest('GET', `${environment.apiUrl}` + '/Common/GetZip?projectId=' + projectId, {
            responseType: 'arraybuffer',
            reportProgress: true
        });
    }
    public getManufacturerProjectQuote(projectId: number): Observable<ResultModel> {
        const path = '/Manufacturer/GetManufacturerProjectQuoteDetail?ProjectId=' + projectId;
        return this._apiService.get<ResultModel>(path);
    }
    public saveUpdateManufacturerProjectQuote(model: ProjectQuoteDetail): Observable<ResultModel> {
        const path = '/Manufacturer/SaveUpdateProjectQuote';
        return this._apiService.post(path, model);
    }
    public getIndustriesAndCategory(): Observable<ResultModel> {
        const path = '/Manufacturer/GetIndustriesAndCategory';
        return this._apiService.get<ResultModel>(path);
    }
    public getProjectTypesByCategoryId(categoryId: number): Observable<ResultModel> {
        const path = '/Common/GetProjectTypesByCategoryId?categoryId=' + categoryId;
        return this._apiService.get<ResultModel>(path);
    }
    public getManufacturerProfileDetail(ProfileId: number): Observable<ResultModel> {
        const path = '/Manufacturer/GetManufacturerProfile?ProfileId=' + ProfileId;
        return this._apiService.get<ResultModel>(path);
    }
    public getManufacturerNotifications(): Observable<ResultModel> {
        const path = '/Manufacturer/GetManufacturerNotifications';
        return this._apiService.get<ResultModel>(path);
    }
    public getManufacturerNotificationsCount(): Observable<ResultModel> {
        const path = '/Manufacturer/GetManufacturerNotificationsCount';
        return this._apiService.get<ResultModel>(path);
    }
    public updateReadNotification(NotificationId: Number): Observable<ResultModel> {
        const path = '/Manufacturer/UpdateReadNotification?NotificationId=' + NotificationId;
        return this._apiService.post(path, NotificationId);
    }
    public getAccountSetting(): Observable<ResultModel> {
        const path = '/Manufacturer/GetManufacurerProfileSettingInfo';
        return this._apiService.get<ResultModel>(path);
    }
    public changePassword(model: changePassword): Observable<ResultModel> {
        const path = '/Account/ChangePassword';
        return this._apiService.post(path, model);
    }
    public updateSocialLinks(account: accountSetting): Observable<ResultModel> {
        const path = '/Manufacturer/UpdateManufacturerSocialMediaInfo';
        return this._apiService.post(path, account);
    }
    public uploadProfileImage(profileImage: FileUploadModel): Observable<ResultModel> {
        const path = '/Common/UpdateProfileImage';
        return this._apiService.post(path, profileImage);
    }
    public getCreditCardDetails(): Observable<ResultModel> {
        const path = '/Manufacturer/GetCardsDetails';
        return this._apiService.get<ResultModel>(path);
    }
    public updatePaymentMethod(model: creditCardDetail) {
        const path = '/Manufacturer/UpdateManufacturerCard';
        return this._apiService.post(path, model);
    }
    public getManufacturerAllReviews(mid: number): Observable<ResultModel> {
        const path = '/Manufacturer/GetManufacturerAllReviews?mid=' + mid;
        return this._apiService.get<ResultModel>(path);
    }
    public getManufacturerPlanDetails(): Observable<ResultModel> {
        const path = '/Manufacturer/GetManufacturerPlanDetail';
        return this._apiService.get(path);
    }
    public downloadInvoice(invoiceNumber: number): Observable<ResultModel> {
        const path = '/Manufacturer/DownloadInvoice?invoiceNumber=' + invoiceNumber;
        return this._apiService.get(path);
    }
    public cancelSubscription(): Observable<ResultModel> {
        const path = '/Manufacturer/PlanCancellationNotifiaction';
        return this._apiService.get(path);
    }
    public deleteOwnerProfilePic(obj): Observable<ResultModel> {
        const path = '/Manufacturer/DeleteCompanyOwnerImage';
        return this._apiService.post(path, obj);
    }
    public deleteProfilePicture(obj): Observable<ResultModel> {
        const path = '/Common/DeleteProfileImage';
        return this._apiService.post(path, obj);

    }
    public uploadDocument(model: any): Observable<ResultModel> {
        //const headers = new Headers();
        const path = '/Common/UploadDocument';
        return this._apiService.post(path, model);
    }

    public getUnreadMessageCount(): Observable<ResultModel> {
        const path = '/Common/GetUnreadMessageCount';
        return this._apiService.get(path);
    }

    public getManufacturerHistory(profileId): Observable<ResultModel> {
        const path = '/Manufacturer/GetManufacturerProductionHistory?ProfileId=' + profileId;
        return this._apiService.get(path);
    }
    public getProjectRange(): Observable<ResultModel> {
        const path = '/Manufacturer/GetProjectRange';
        return this._apiService.get(path);
    }
    public getInvoiceDetail(invoiceNumber: number): Observable<ResultModel> {
        const path = '/Manufacturer/DownloadInvoice?invoiceNumber=' + invoiceNumber;
        return this._apiService.get(path);
    }
}
