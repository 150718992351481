import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { JwtService } from './jwt.service';
import { ICurrentUser } from '../Models/Current-User';
import { NotifierService } from 'angular-notifier';
// import { JwtService } from './jwt.service';

@Injectable()
export class ApiService {
  //local fields
  private _currentUserSubject: BehaviorSubject<ICurrentUser>;
  public _currentUser: Observable<ICurrentUser>;
  public _notifier: NotifierService;
  //constructor
  constructor(private http: HttpClient, private jwtService: JwtService, private __notifier: NotifierService) {
    this._currentUserSubject = new BehaviorSubject<ICurrentUser>(JSON.parse(localStorage.getItem("currentUser")));
    this._currentUser = this._currentUserSubject.asObservable();
    this._notifier = __notifier;
  }

  private setHeaders(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
    if (this.jwtService.getToken()) {
      headersConfig['Authorization'] = 'Bearer ' + this.jwtService.getToken();
    }
    return new HttpHeaders(headersConfig);
  };

  private formatErrors(error: any) {
    return throwError(error);
  }

  get<ResultModel>(path: string, searchParams: HttpParams = new HttpParams()): Observable<ResultModel> {
    return this.http.get<ResultModel>(`${environment.apiUrl}${path}`, { headers: this.setHeaders(), params: searchParams }
    ).pipe(catchError(this.formatErrors));
  }

  put<ResultModel>(path: string, body: object = {}): Observable<ResultModel> {
    return this.http.put<ResultModel>(`${environment.apiUrl}${path}`, JSON.stringify(body), { headers: this.setHeaders() })
      .pipe(catchError(this.formatErrors));
  }

  post<ResultModel>(path: string, body: Object = {}): Observable<ResultModel> {
    return this.http.post<ResultModel>(`${environment.apiUrl}${path}`, JSON.stringify(body), { headers: this.setHeaders() })
      .pipe(catchError(this.formatErrors));
  }
  delete<ResultModel>(path: string): Observable<ResultModel> {
    return this.http.delete<ResultModel>(`${environment.apiUrl}${path}`, { headers: this.setHeaders() })
      .pipe(catchError(this.formatErrors));
  }
  signIn(username: string, password: string, _rememberme: string) {
    var body = new URLSearchParams();
    body.set('username', username);
    body.set('password', password);
    body.set('rememberme', _rememberme);

    //set header
    let options = { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') };

    var IObservable = this.http.post<any>(`${environment.apiUrl}` + "/Authentication/Token", body.toString(), options);

    return IObservable
      .pipe(map(user => {
        if (user.success == false) return user;
        if (user.data && user.data.token) {
          user.data.isExpired = user.isExpired || false;
          this.jwtService.saveToken(user.data, user.data.rememberme === "true");
          this._currentUserSubject.next(user.data);
        }
        return user;
      }));
  }

  getTrustedManufecturers(limit = 3) {
    const data = [
      { id: 1, name: 'Ess Key'},
      { id: 2, name: 'Sports King'},
      { id: 3, name: 'Softobiz'},
      { id: 1, name: 'HCL'},
      { id: 2, name: 'Infosys'},
      { id: 1, name: 'Ess Key'},
      { id: 2, name: 'Sports King'},
      { id: 3, name: 'Softobiz'},
      { id: 1, name: 'HCL'},
      { id: 2, name: 'Infosys'},
      { id: 1, name: 'Ess Key'},
      { id: 2, name: 'Sports King'},
      { id: 3, name: 'Softobiz'},
      { id: 1, name: 'HCL'},
      { id: 2, name: 'Infosys'},
      { id: 1, name: 'Ess Key'},
      { id: 2, name: 'Sports King'},
      { id: 3, name: 'Softobiz'},
      { id: 1, name: 'HCL'},
      { id: 2, name: 'Infosys'},
    ];

    return of(data.slice(0, limit));
  }
}
