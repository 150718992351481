import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationEnd, NavigationStart } from '@angular/router';
import { JwtService } from '../core/services/jwt.service';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DashBoardAuthGuard implements CanActivate {
  //local fields
  currentUrl = null;
  previousUrl = null;
  constructor(
    private router: Router,
    private authenticationService: JwtService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.getTokenData();
    if (currentUser) {
      this.redirectToRoute(currentUser["role"]);
      return false;
    } else {
      return true;
    }
  }
  public redirectToRoute(role) {
    if (role === 'Inventor') {
      this.router.navigate(['/inventor', 'myProjects']);
    } else if (role === 'Manufacturer') {
      this.router.navigate(['/manufacturer', 'search-project']);
    } else if (role === 'Admin') {
      this.router.navigate(['/admin', 'adminDashboard']);
    }
  }
}