import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { JwtService } from '../core/services/jwt.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private auth: JwtService, private router: Router) {
        //
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let self = this;
        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                // do stuff with response if you want
            }
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    self.router.navigate(['main', 'dashboard']);
                    window.localStorage.clear();
                    window.sessionStorage.clear();
                    return;
                } else if (err.status === 403) {
                    window.localStorage.clear();
                    window.sessionStorage.clear();
                    self.router.navigate(['main', 'accessdenied']);
                }
            }
        }));
    }
}