export class HelpModel {

    // constructor
    constructor() { }

    // fields
    public profileId: number;
    public name: string;
    public email: string;
    public query: string;

}
