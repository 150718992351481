import { FormControl } from '@angular/forms';

export interface ValidationResult {
    [key: string]: boolean;
}

export class PasswordValidator {

    public static strong(control: FormControl): ValidationResult {
        let valid = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,}/.test(control.value);
        // let hasUpper = /[A-Z]/.test(control.value);
        // let hasLower = /[a-z]/.test(control.value);
        //const valid = hasNumber && hasUpper && hasLower;
        if (!valid) {
            return { strong: true };
        }
        return null;
    }
}