export class PasswordResetModel {
    //constructor
    constructor() { }

    //fields
    public Password: string;
    public ConfirmPassword: string;
    public Code: string;
    public Email: string;

}