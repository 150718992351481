import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialogRef} from '@angular/material';
import { SignInModel } from '../../Models/SignInModel';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SignUpService } from '../../services/SignUpService';
import { HelperMethod } from '../../Helper/HelperMethod';
import { JwtService } from '../../services/jwt.service';
import { CommponMethod } from '../../Helper/CommonMethods';
import { GlobalService } from '../../services/global.service';

declare var $: any;

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent implements OnInit {

  /** local fields **/
  private _signInModel = new SignInModel();
  public _signInForm: FormGroup;
  public _formSubmited: boolean;
  public _showMe: boolean = false;
  public inProjectExist: boolean = false;
  public subscriberType: string;
  public returnUrl: string = null;
  /** constructor **/
  constructor(
    private _dialogRef: MatDialogRef<LoginFormComponent>,
    private _fb: FormBuilder,
    private globalService: GlobalService,
    private _signUpServive: SignUpService,
    private _jwtService: JwtService,
    private _helperMethod: HelperMethod,
    private _commonMethod: CommponMethod,
    private activateRoute: ActivatedRoute,
    private _router: Router) {
    this.activateRoute.queryParams.subscribe(params => {
      this.returnUrl = params["returnUrl"] ? params["returnUrl"] : null;
    });
  }

  /** hooks **/
  ngOnInit() {
    window.scrollTo(0, 0);
    this.initilizeSignForm();
  }
  ngAfterViewInit() {
    this.autoFillCredientials();
  }

  /** methods **/
  public closeModal(): void {
    this._dialogRef.close();
  }
  public showSignInSection(): void {
    this._showMe = !this._showMe;
  }

  /** initilize form builder **/
  private initilizeSignForm(): void {
    this._signInForm = this._fb.group({
      username: this._fb.control(this._signInModel.username, [Validators.required, Validators.email]),
      password: this._fb.control(this._signInModel.password, [Validators.required]),
      rememberme: this._fb.control(this._signInModel.rememberme)
    })
  }

  public get f() { return this._signInForm.controls; }

  public onSignIn(): void {
    this._formSubmited = true;
    let _this = this;
    if (this._signInForm.valid) {
      this._commonMethod.showLoader();
      this.removeFiltersFromLocal();
      this.removeProjectFromLocalStorage();
      let _signInParams = this._signInForm.value
      this._signUpServive.SignIn(_signInParams.username, _signInParams.password, _signInParams.rememberme).subscribe(result => {
        if (result.success) {
          _this._jwtService.removeProjectFromToken();
          _this.rememberMe();
          _this.closeModal();
          if (_this.returnUrl) {
            _this._router.navigateByUrl(this.returnUrl);
            return;
          }
          if (result.data.role == 'Manufacturer') {
            _this._router.navigate(["/manufacturer", "info"]);
          }
          else if (result.data.role === 'Inventor') {
            _this._router.navigate(["/inventor", "myProjects"]);
          }
          else if (result.data.role === 'Admin') {
            _this._router.navigate(['/admin', 'adminDashboard']);
          }
        }
        else {
          this._commonMethod.hideLoader();
          _this.globalService.showNotification("error", result.errorMessage);
        }
        _this._commonMethod.hideLoader();
      }, (error) => {
        this._commonMethod.hideLoader();
      })
    }
  }
  private removeProjectFromLocalStorage() {
    localStorage.removeItem('inventorProject');
  }

  public createAccount(): void {
    this.closeModal();
    this._router.navigate(["/main", "signup", "Inventor"]);
  }

  private rememberMe(): void {
    let _signInParams = this._signInForm.value
    if (_signInParams.rememberme) {
      localStorage.setItem("username", _signInParams.username);
      localStorage.setItem("password", _signInParams.password);
      localStorage.setItem("rememberme", _signInParams.rememberme);
    }
    else {
      localStorage.removeItem("username");
      localStorage.removeItem("password");
      localStorage.removeItem("rememberme");
    }
  }

  public SignUpWithLinkedIn(_userRole: string): void {
    this.removeFiltersFromLocal();
    let _role = this._helperMethod.RoleIdByName(_userRole);
    if (_role > 0) {
      this._jwtService.saveUserRole(_role.toString());
    } 
     window.location.href='https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86ld9gsm7481no&redirect_uri=https://manufacturefy.com/main/LinkedInToken&state=DCEeFWf45A53sdfKef468&scope=openid%20profile%20w_member_social%20email'
  }

  public onForgotPassword(): void {
    this.closeModal();
    this._router.navigate(["/main", "forgot-password"]);
  }
  private removeFiltersFromLocal() {
    const localData = localStorage.getItem('filters');
    const manufacturerFilters = localStorage.getItem('manufacturerSearchFilters');
    if (localData) {
      localStorage.removeItem('filters');
    }
    if (manufacturerFilters) {
      localStorage.removeItem('manufacturerSearchFilters');
    }
  }
  private autoFillCredientials(): void {
    let flag = localStorage.getItem("rememberme");
    if (flag) {
      this._signInForm.controls.username.setValue(localStorage.getItem("username"));
      this._signInForm.controls.password.setValue(localStorage.getItem("password"));
      this._signInForm.controls.rememberme.setValue(localStorage.getItem("rememberme"));
    }
  }

}
