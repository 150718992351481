import { NgxSpinnerService } from 'ngx-spinner';
import { Injectable } from '@angular/core';

@Injectable()
export class CommponMethod {
    //constructor
    constructor(private _spinner: NgxSpinnerService) { }

    //methods
    public showLoader(): void {
        this._spinner.show();
    }
    public hideLoader(): void {
        setTimeout(() => {
            this._spinner.hide();
        }, 500);
    }
}