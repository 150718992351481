import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { SignUpService } from '../../services/SignUpService';
import { Router, ActivatedRoute } from '@angular/router';
import { PasswordResetModel } from '../../Models/PasswordResetModel';
import { MustMatch } from '../../Helper/CustomValidation';
import { PasswordValidator } from '../../Helper/PasswordStrength';
import { CommponMethod } from '../../Helper/CommonMethods';
import { GlobalService } from '../../services/global.service';

@Component({
    selector: 'reset-password',
    templateUrl: "./passwordReset.component.html"
})
export class ResetPasswordComponent {
    /** local fields **/
    public _resetPasswordForm: FormGroup;
    private _notifier: NotifierService;
    public _passResetModel = new PasswordResetModel();
    /** constructor **/
    constructor(private _signUpService: SignUpService, private globalService: GlobalService, private _commonMethod: CommponMethod, private _activatedRoute: ActivatedRoute, private _router: Router, private _fb: FormBuilder, private __notifier: NotifierService) {
        this._notifier = __notifier;
    }

    /** hooks **/
    ngOnInit() {
        let _this = this;
        this._activatedRoute.queryParams.subscribe(params => {
            if (params.code) {
                _this._passResetModel.Code = params.code;
                _this._passResetModel.Email = params.userId;
            }
        });
        this.initilizeResetPasswordForm();
    }

    /** methods **/
    private initilizeResetPasswordForm(): void {
        this._resetPasswordForm = this._fb.group({
            Password: this._fb.control(this._passResetModel.Password, [Validators.required, Validators.minLength(8), this.noWhitespaceValidator, PasswordValidator.strong.bind(this)]),
            ConfirmPassword: this._fb.control(this._passResetModel.ConfirmPassword, [Validators.required])
        }, { validator: MustMatch('Password', 'ConfirmPassword') })
    }

    public get f() { return this._resetPasswordForm.controls; }

    public onSubmit(): void {
        if (this._resetPasswordForm.valid) {
            this._commonMethod.showLoader();
            let _this = this;
            let params = this._resetPasswordForm.value;
            this._passResetModel.Password = params.Password;
            this._passResetModel.ConfirmPassword = params.ConfirmPassword;
            this._signUpService.PasswordReset(this._passResetModel).subscribe(result => {
                if (result.success) {
                    _this.globalService.showNotification("success", result.successMessage);
                    _this._router.navigate(["/main", "passwordChangedSuccess"]);
                }
                else {
                    _this.globalService.showNotification("sucerrorcess", result.errorMessage);
                    _this._commonMethod.hideLoader();
                    _this._router.navigate(["/main", "dashboard"]);
                }
                
            }, (error) => {
                _this._commonMethod.hideLoader();
            })

        }
    }

    /** validation methods **/
    private noWhitespaceValidator(control: FormControl) {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
    }
}