import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
declare var $: any;

@Component({
  selector: 'app-thankyou-feedback',
  templateUrl: './thankyou-feedback.component.html',
  styleUrls: ['./thankyou-feedback.component.css']
})

export class ThankYouFeedbackComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ThankYouFeedbackComponent>) { }
  ngOnInit() { }
  clearFeedbackdata() { this.dialogRef.close(); }

}
