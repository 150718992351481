import { Component, OnInit } from '@angular/core';
import { JwtService } from 'src/app/core/services/jwt.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {

  //local fields
  public isLogedIn: boolean = false;
  constructor(private jwtService: JwtService) { }
  ngOnInit() {
    this.isLogedIn = this.jwtService.isTokenAvailable() > 0;
    window.scroll(0, 0);
  }

}
