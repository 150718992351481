import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { TimeAgoPipe } from 'time-ago-pipe';

@NgModule({
    declarations: [
        TimeAgoPipe
    ],
    imports: [],
    exports: [
        TimeAgoPipe
    ],
    entryComponents: [],
})
export class GlobalModule { }
