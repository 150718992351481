import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { Observable } from 'rxjs';
import { ResultModel } from '../../core/Models/Result-Model';
import { JwtService } from '../../core/services/jwt.service';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { searchProject } from '../Models/SearchProject';
import { searchManufacturer } from '../Models/SearchManufacturer';
import { NewProjectData } from '../components/new-project/Models/NewProjectData';
import { ManufacturerReview } from '../Models/ProjectDetail/ManufacturerReview';
import { FileUploadModel } from '../Models/FileUploadModel';
import { ChangePassword } from '../Models/InventorProfileSettings/ChangePassword';
import { InventorCompanyInfo } from '../Models/InventorCompanyInfo';


@Injectable()
export class InventorService {

    constructor(private _apiService: ApiService, private http: HttpClient, private _jwtService: JwtService) { }

    public getIndustries(): Observable<ResultModel> {
        const path = '/Inventor/GetIndustriesInfo';
        return this._apiService.get<ResultModel>(path);
    }
    public getCategoriesByIndustryId(IndustryId: number): Observable<ResultModel> {
        const path = '/Inventor/GetCategoriesByIndustry?IndustryId=' + IndustryId;
        return this._apiService.get<ResultModel>(path);
    }
    public getManufacturerWithMatchScore(ProjectId: number): Observable<ResultModel> {
        const path = '/Inventor/GetManufacturerWithMatchScore?ProjectId=' + ProjectId;
        return this._apiService.get<ResultModel>(path);
    }
    public getSearchInventorProjects(_model: searchProject): Observable<ResultModel> {
        //const headers = new Headers();
        const path = '/Inventor/GetSearchInventorProjects';
        return this._apiService.post(path, _model);
    }
    public getAllProjectStatus(): Observable<ResultModel> {
        const path = '/Inventor/GetAllProjectStatus';
        return this._apiService.get<ResultModel>(path);
    }
    public getProjectMaterials(): Observable<ResultModel> {
        const path = '/Inventor/GetAllMaterials';
        return this._apiService.get<ResultModel>(path);
    }
    public getProjectTypesByCategoryId(categoryId: number): Observable<ResultModel> {
        const path = '/Common/GetProjectTypesByCategoryId?categoryId=' + categoryId;
        return this._apiService.get<ResultModel>(path);
    }
    public getInventorSearchManufacturerData(_model: searchManufacturer): Observable<ResultModel> {
        const path = '/Inventor/GetInventorSearchManufacturerData';
        return this._apiService.post(path, _model);
    }
    public getProjectInfoFromStatus(projectId: number): Observable<ResultModel> {
        const path = '/Inventor/GetProjectInfoFromStatus?ProjectId=' + projectId;
        return this._apiService.get<ResultModel>(path);
    }
    public deleteProject(projectId: number): Observable<ResultModel> {
        const path = '/Inventor/DeleteProject?ProjectId=' + projectId;
        return this._apiService.post<ResultModel>(path);
    }
    public postProject(projectId: number): Observable<ResultModel> {
        const path = '/Inventor/PostProject?ProjectId=' + projectId;
        return this._apiService.post<ResultModel>(path);
    }
    public assignProjectToManufacturer(projectId: number, manufacturerId: number): Observable<ResultModel> {
        const path = '/Inventor/AssignProjectToManufacturer?ProjectId=' + projectId + '&ManufacturerId=' + manufacturerId;
        return this._apiService.post<ResultModel>(path);
    }
    public closeProject(projectId: number): Observable<ResultModel> {
        const path = '/Inventor/CloseProject?ProjectId=' + projectId;
        return this._apiService.post<ResultModel>(path);
    }
    public submitManufacturerReview(_model: ManufacturerReview): Observable<ResultModel> {
        const path = '/Inventor/SubmitManufacturerReview';
        return this._apiService.post(path, _model);
    }
    public getInventorProfileSettings(): Observable<ResultModel> {
        const path = '/Inventor/GetInventorAccountSettingInfo';
        return this._apiService.get<ResultModel>(path);
    }
    public uploadImage(model: any): Observable<ResultModel> {
        const path = '/Common/UploadAttachment';
        return this._apiService.post(path, model);
    }
    public uploadProfileImage(profileImage: FileUploadModel): Observable<ResultModel> {
        const path = '/Common/UpdateProfileImage';
        return this._apiService.post(path, profileImage);
    }
    public changePassword(model: ChangePassword): Observable<ResultModel> {
        const path = '/Account/ChangePassword';
        return this._apiService.post(path, model);
    }

    public saveNewInventorProject(project: NewProjectData): Observable<ResultModel> {
        var path = "/Inventor/SaveUpdateProjectDetails";
        return this._apiService.post(path, project);
    }
    public getManufacturerProfile(ProfileId: number): Observable<ResultModel> {
        const path = '/Manufacturer/GetManufacturerProfile?ProfileId=' + ProfileId;
        return this._apiService.get<ResultModel>(path);
    }
    public deleteProfilePicture(obj): Observable<ResultModel> {
        var path = "/Common/DeleteProfileImage";
        return this._apiService.post(path, obj);
    }
    public getProjectDetailByProjectId(ProjectId: number): Observable<ResultModel> {
        var path = "/Inventor/GetProjectDetails?ProjectId=" + ProjectId;
        return this._apiService.get(path);
    }
    public updateCompanyName(inventorCompanyInfo: InventorCompanyInfo): Observable<ResultModel> {
        const path = '/Inventor/UpdateCompanyName';
        return this._apiService.post(path, inventorCompanyInfo);
    }
    public addProjectToTempTable(data: NewProjectData): Observable<ResultModel> {
        const path = '/Inventor/SaveTempProjectDetails';
        return this._apiService.post(path, data);
    }
    public getProjectFromTempTable(tempProjectId: number): Observable<ResultModel> {
        const path = '/Inventor/GetTempProjectDetails?tempProjectId=' + tempProjectId;
        return this._apiService.get(path);
    }
}
