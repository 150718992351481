import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { Observable } from 'rxjs';
import { JwtService } from '../../core/services/jwt.service';
import { HttpClient } from '@angular/common/http';
import { ResultModel } from '../../core/Models/Result-Model';
import { NewMessage } from '../models/Inbox/NewMessage';
import { debounceTime, map } from 'rxjs/operators';

@Injectable()
export class messageService {

    constructor(private _apiService: ApiService, private http: HttpClient, private _jwtService: JwtService) { }

    public getUsersList(page: number, pageSize: number): Observable<ResultModel> {
        var path = "/Common/GetUserMessages?page=" + page + "&pageSize=" + pageSize;
        return this._apiService.get<ResultModel>(path);
    }
    public getMessagesByUser(projectId: number, senderId: number): Observable<ResultModel> {
        var path = "/Common/GetMessages?projectId=" + projectId + "&senderId=" + senderId;
        return this._apiService.get<ResultModel>(path);
    }
    public postNewMessage(model: NewMessage): Observable<ResultModel> {
        var path = "/Common/SaveMessage";
        return this._apiService.post(path, model);
    }
    public getInventorNames(str: string) {
        var path = "/Manufacturer/SearchProjectInventors?KeyWord=" + str;
        var inventorProjects = this._apiService.get(path)
            .pipe(
                debounceTime(500),
                map(
                    (data: any) => { return data; }
                ));
        return inventorProjects;
    }
    public getManufacturers(str: string) {
        var path = "/Inventor/SearchManufacturer?KeyWord=" + str;
        var manuFacturerNames = this._apiService.get(path)
            .pipe(
                debounceTime(500),
                map(
                    (data: any) => { return data; }
                ));
        return manuFacturerNames;
    }
    public getInventorProjects(inventorId: number) {
        var path = "/Common/GetAllProjects?InventorId=" + inventorId;
        var manuFacturerNames = this._apiService.get(path)
            .pipe(
                debounceTime(500),
                map(
                    (data: any) => { return data; }
                ));
        return manuFacturerNames;
    }
    //
}