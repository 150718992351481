import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { HelpModel } from '../../Models/HelpModel';
import { CommponMethod } from 'src/app/core/Helper/CommonMethods';
import { GlobalService } from 'src/app/core/services/global.service';
import { onlycharandspace, emailreg } from 'src/app/core/const';
import { HelpService } from '../../services/HelpService';
import { JwtService } from 'src/app/core/services/jwt.service';
declare var $: any;

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {

  public _helpForm: FormGroup;
  public _formSubmited: boolean;
  private _helpModel = new HelpModel();
  public regexp = { onlychar: onlycharandspace, emailreg: emailreg };
  public userId: number;
  public name: string;
  public email: string;
  constructor(private _jwtService: JwtService,
    private _dialogRef: MatDialogRef<HelpComponent>,
    private _commonMethod: CommponMethod,
    private dialog: MatDialog,
    private _fb: FormBuilder,
    private globalService: GlobalService,
    private helpService: HelpService) { }

  ngOnInit() {
    const _result = this._jwtService.getTokenData();
    if (_result) {
      this.userId = _result['userId'];
      this.name = _result['name'];
      this.email = _result['email'];
    }
    this.initilizeHelpForm(this.userId, this.name, this.email);
  }

  public get f() { return this._helpForm.controls; }

  private initilizeHelpForm(profileId: number, name: string, email: string): void {
    this._helpForm = this._fb.group({
      profileId: this._fb.control(this._helpModel.profileId = profileId),
      name: this._fb.control(this._helpModel.name = name, [Validators.required, Validators.pattern(this.regexp.onlychar)]),
      email: this._fb.control(this._helpModel.email = email, [Validators.required, Validators.pattern(this.regexp.emailreg)]),
      query: this._fb.control(this._helpModel.query, [Validators.required])
    });
  }

  submitQuery(): void {
    const self = this;
    self._formSubmited = true;
    const _signInParams = this._helpForm.value;
    if (self._helpForm.valid) {
      self._helpForm.patchValue({
        name: this.name,
        email: this.email
      });
      self._commonMethod.showLoader();
      self.helpService.SendHelpEmail(self._helpForm.value).subscribe(response => {
        if (response.success) {
          self._dialogRef.close({ data: true });
          self._commonMethod.hideLoader();
        } else {
          self._dialogRef.close({ data: false });
          self.globalService.showNotification('error', 'Something went wrong');
          self._commonMethod.hideLoader();
        }
      }, (error) => {
        self._dialogRef.close({ data: false });
        self.globalService.showNotification('error', 'Something went wrong');
        self._commonMethod.hideLoader();
      });
      self._commonMethod.hideLoader();
    }
  }

}
