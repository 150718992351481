import { NgModule } from '@angular/core';
import { Routes, RouterModule, RoutesRecognized } from '@angular/router';
import { DashboardComponent } from './core/components/dashboard/dashboard.component';
import { SignupComponent } from './core/components/signup/signup.component';
import { ConfirmationEmailComponent } from './core/components/confirmation-email/confirmation-email.component';
import { ForgotPswdComponent } from './core/components/forgot-pswd/forgot-pswd.component';
import { ResetPasswordComponent } from './core/components/password-reset/passwordReset.component';
import { EmailConfirmSuccessfully } from './core/components/EmailConfirmSuccess/emailConfirmSuccessfully.component';
import { PasswordResetSuccess } from './core/components/PasswordResetSuccess/passwordResetSuccess.component';
import { WelcomeScreenComponent } from './core/components/welcome-screen/welcome-screen.component';
import { SubscriptionPlan } from './core/components/SubscriptionPlan/subscriptionPlan.component';
import { CreateNewProjectComponent } from './core/components/CreateNewProject/CreateNewProject';
import { LinkedInAccessToken } from './core/components/linkedInAccessToken/linkedInAccessToken.component';
import { UiKitComponent } from './core/components/ui-kit/ui-kit.component';
import { LinkedInSignUpComponent } from './core/components/LinkedInSignUpForManufacturer/LinkedInSignup.component';
import { CreditCardComponent } from './core/components/creditCard/creditCard.component';
import { ForgotPasswordSuccess } from './core/components/ForgotPasswordSuccess/forgotpassword.component';
import { InboxComponent } from './shared/components/inbox/inbox.component';
import { CreateRequestGuardService } from './core/routeGuards/my-guard.service';
import { notAuthorizeComponent } from './shared/components/notAuthorize/notauthorize.component';
import { DashBoardAuthGuard } from './guards/dashboard.auth.guard';
import { NoProjectFoundComponent } from './shared/components/noProjectFound/noProjectFound.component';
import { UserAgreementComponent } from './core/components/useragreement/useragreement.component';
import { NoDataFoundComponent } from './core/components/404/404.component';
import { TermsAndConditionsComponent } from './shared/components/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './shared/components/privacy-policy/privacy-policy.component';
import { ManufacturersComponent } from './core/components/manufacturers/manufacturers.component';
import { ManufacturerDetailComponent } from './core/components/manufacturer-detail/manufacturer-detail.component';

//Do not change routing without permission or discussion it may affect some other functionality
const routes: Routes = [
  {
    path: 'main', children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent, canActivate: [DashBoardAuthGuard] },
      { path: 'dashboard/manufacturers', component: ManufacturersComponent, canActivate: [DashBoardAuthGuard] },
      { path: 'dashboard/manufacturers/:id', component: ManufacturerDetailComponent, canActivate: [DashBoardAuthGuard] },
      { path: 'signup/:type', component: SignupComponent, canActivate: [DashBoardAuthGuard] },
      { path: 'confirmation', component: ConfirmationEmailComponent },
      { path: 'forgot-password', component: ForgotPswdComponent },
      { path: 'resetPassword', component: ResetPasswordComponent, canActivate: [DashBoardAuthGuard] },
      { path: 'emailConfirmSuccess', component: EmailConfirmSuccessfully },
      { path: 'passwordChangedSuccess', component: PasswordResetSuccess, canActivate: [DashBoardAuthGuard] },
      { path: 'successScreen', component: WelcomeScreenComponent, canActivate: [DashBoardAuthGuard] },
      { path: 'subscriptionPlan', component: SubscriptionPlan, canActivate: [DashBoardAuthGuard] },
      { path: 'LinkedInToken', component: LinkedInAccessToken, canActivate: [DashBoardAuthGuard] },
      { path: 'LinkedInSignUp', component: LinkedInSignUpComponent, canActivate: [DashBoardAuthGuard] },
      { path: 'creditCard', component: CreditCardComponent },
      { path: 'Forgotpswd', component: ForgotPswdComponent, canActivate: [DashBoardAuthGuard] },
      { path: 'UiKit', component: UiKitComponent },
      { path: 'passwordResetScreen', component: ForgotPasswordSuccess, canActivate: [DashBoardAuthGuard] },
      { path: 'dashboard', component: DashboardComponent, canActivate: [DashBoardAuthGuard] },
      { path: 'inbox', component: InboxComponent },
      { path: 'useragreement', component: UserAgreementComponent },
      { path: 'accessdenied', component: notAuthorizeComponent },
      { path: 'nodatafound', component: NoProjectFoundComponent },
      { path: '404', component: NoDataFoundComponent },
      { path: 'termandcondition', component: TermsAndConditionsComponent },
      { path: 'privacypolicy', component: PrivacyPolicyComponent },
    ]
  },
  { path: 'inventor', loadChildren: './Inventor/inventor.module#InventorModule' },
  { path: 'manufacturer', loadChildren: './Manufacturer/manufacturer.module#ManufacturerModule' },
  { path: 'admin', loadChildren: './admin/admin.module#AdminModule' },

  { path: '**', redirectTo: 'main', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class RoutingModule { }
