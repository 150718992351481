import { Component } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { CommponMethod } from './core/Helper/CommonMethods';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'manufacturefy';

  //constructor

  constructor(private router: Router, private commonMethod: CommponMethod) {
    this.router.events.subscribe(evt => {
      switch (true) {
        case evt instanceof NavigationStart: {
          commonMethod.showLoader();
        }
        case evt instanceof NavigationEnd: {
          commonMethod.hideLoader();
        }
        case evt instanceof NavigationError: {
          commonMethod.hideLoader();
        }
        default:
          break;
      }
      window.scroll(0,0);
    })
  }
}
