import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderComponent } from '../header/header.component';
import { InventorService } from 'src/app/Inventor/Services/InventorService';
import { CommponMethod } from '../../Helper/CommonMethods';
import { ProjectItem } from 'src/app/Manufacturer/Models/ProjectSearch/ProjectItem';
import { JwtService } from '../../services/jwt.service';
import { ManufacturerService } from '../../services/manufacturer.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {
  public industries: Array<ProjectItem> = new Array<ProjectItem>();
  public manufacturers: Array<{}>;
  private sub: Subscription = new Subscription();

  @ViewChild(HeaderComponent) header: HeaderComponent;

  constructor(private _activatedRoute: ActivatedRoute,
    private commonMethod: CommponMethod,
    private route: Router,
    private router: Router,
    private jwtService: JwtService,
    private service: InventorService,
    private manufacturerService: ManufacturerService) { }

  ngOnInit() {
    let that = this;
    this.commonMethod.hideLoader();
    this._activatedRoute.queryParams.subscribe(params => {
      var mode = params["m"];
      if (mode == "signin") {
        window.scroll(0, 0);
        that.header.openLoginForm();
      }
    });
    that.getIndustries();
    this.getTrustedManufecturers();
  }
  private getIndustries(): void {
    let self = this;
    self.commonMethod.showLoader();
    self.service.getIndustries().subscribe(result => {
      if (result["success"]) {
        self.industries = result.data.splice(0, 4);
        self.commonMethod.hideLoader();
      }
      else {
        self.commonMethod.hideLoader();
      }
    }, (error) => {
      self.commonMethod.hideLoader();
    })
  }
  public goToProjectPage(id: number): void {
    let self = this;
    self.jwtService.removeProjectFromToken();
    this.router.navigate(["/inventor", "newproject"], { queryParams: { ind: id } });
  }
  public redirectToProjectScreen(): void {
    this.commonMethod.showLoader();
    this.jwtService.removeProjectFromToken();
    this.route.navigate(["/inventor", "newproject"]);
  }
  getTrustedManufecturers() {
    this.sub = this.manufacturerService.getUnclaimedManufacturers(1, 3)
      .subscribe(resData => {
        if (resData.success) {
          this.manufacturers = resData.data
        }
      });
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
