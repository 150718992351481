import { UserRole } from '../Enum/UserRole';
import { Injectable } from '@angular/core';

@Injectable()
export class HelperMethod {
    RoleIdByName(_role: string): Number {
        try {
            if (_role) {
                return UserRole[_role];
            }
            else {
                throw new TypeError("Invalid data");
            }
        }
        catch (e) {
            console.log((<Error>e).message);
        }
    }
}