import { Component, OnInit } from '@angular/core';
import { JwtService } from '../../services/jwt.service';
import { Router } from '@angular/router';

export interface type {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'create-new-project',
  templateUrl: './CreateNewProject.html',
})

export class CreateNewProjectComponent implements OnInit {

  public isLinear: boolean = false;
  public _name: string = "";
  public _userLoggedIn: boolean = false;

  constructor(private _jwtService: JwtService, private _route: Router) { }


  ngOnInit() {
    var _result = this._jwtService.getTokenData();
    if (_result) {
      this._name = _result["name"];
    }
    this.checkIfTokenExist();
  }

  public signOut() {
    this._jwtService.deleteToken();
  }

  private checkIfTokenExist() {
    var result = this._jwtService.isTokenAvailable();
    if (result)
      this._userLoggedIn = true;
    else
      this._userLoggedIn = false;

  }
  public redirectToRoute() {
    if (this._userLoggedIn) {
      this._route.navigate(['/main', 'inventorNewProject']);
    }
    else {
      this._route.navigate(['/main', 'dashboard']);
    }
  }
}
