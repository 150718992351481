import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, Renderer2, OnDestroy } from '@angular/core';
import { DataService } from '../../services/data.service';
import { SignUpModel } from '../../Models/SignUpModel';
import { FormGroup, FormBuilder} from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { SignUpService } from '../../services/SignUpService';
import { HelperMethod } from '../../Helper/HelperMethod';
import { CommponMethod } from '../../Helper/CommonMethods';
import { JwtService } from '../../services/jwt.service';
import { GlobalService } from '../../services/global.service';
import { ManufacturerPlan } from '../../Models/ManufacturerPlans';

@Component({
  selector: 'app-linkedInSignup',
  templateUrl: './LinkedInSignup.component.html',
  styleUrls: ['./LinkedInSignup.component.css']
})
export class LinkedInSignUpComponent implements OnInit, OnDestroy {

  /** local fields **/
  public planType: string = "month";
  private _signUpModel: SignUpModel = new SignUpModel();
  public _signUpForm: FormGroup;
  public _formSubmited: boolean = false;
  public _showSection: string = 'plans';
  public token: string;
  public _subscription: Subscription;
  public _subscriptionPlanId = 0;
  private SubscriptionPlans = {
    inventor: new Array<ManufacturerPlan>(),
    monthly: new Array<ManufacturerPlan>(),
    yearly: new Array<ManufacturerPlan>(),
  };
  /** stripe credit card fields **/
  card: any;
  creditCardError: string;
  stripe: any;
  public error: string = null;
  shouldbe: boolean = false;
  cardHandler = this.onChange.bind(this);
  @ViewChild('cardElement') cardElement: ElementRef;

  //constructor
  constructor(private _dataService: DataService,
    private _route: Router,
    private _fb: FormBuilder,
    private cd: ChangeDetectorRef,
    // private renderer: Renderer2,
    private _signupiService: SignUpService,
    private _helperMethod: HelperMethod,
    private globalService: GlobalService,
    private __notifier: NotifierService,
    private _commonMethod: CommponMethod,
    private _jwtService: JwtService) {
    this.__notifier = __notifier;
  }

  //hooks
  ngOnInit() {
    let _this = this;
    this.initlizeSignUpForm();
    this._dataService.getMessage().subscribe(_result => {
      _this._signUpForm.patchValue({
        FirstName: _result.data.localizedFirstName,
        LastName: _result.data.localizedLastName,
        Email: _result.data.emailAddress,
        Role: _result.data.role,
      })
    })
    this.getStripePlans();
  }

  private initlizeSignUpForm(): void {
    this._signUpForm = this._fb.group({
      FirstName: this._fb.control(this._signUpModel.FirstName),
      LastName: this._fb.control(this._signUpModel.LastName),
      Email: this._fb.control(this._signUpModel.Email),
      Password: this._fb.control('Welcome@1'),
      ConfirmPassword: this._fb.control('Welcome@1'),
      Role: this._fb.control(this._signUpModel.Role),
      // cardholdername: this._fb.control(this._signUpModel.CardholderName),
      // MaskedCardNumber: this._fb.control(this._signUpModel.MaskedCardNumber),
      // CardExpiry: this._fb.control(this._signUpModel.MaskedCardNumber),
      // CardToken: this._fb.control(this._signUpModel.MaskedCardNumber),
      // CardType: this._fb.control(this._signUpModel.MaskedCardNumber),
      // SubscriptionId: 0,
      // PhoneNumber: '9999999999',
      // CountryCode: '09',
      LinkedInSignUp: true
    });
  }

  public selectSubscriptionPlan(subscriptionPlanId: number): boolean {
    let flag: boolean = false;
    if (subscriptionPlanId > 0) {
      this._subscriptionPlanId = subscriptionPlanId;
      this._signUpForm.patchValue({
        SubscriptionId: subscriptionPlanId
      });
      flag = true;
    }
    else {
      this.globalService.showNotification("error", "Please choose subscription plan");
    }
    return flag;
  }

  public async _signUp() {
    this._formSubmited = true;
    this._commonMethod.showLoader();
    let _that = this;
     this._signupiService.SignUpManufacturer(this._signUpForm.value).subscribe(res=>{
      if (res.success) {
        this._commonMethod.hideLoader();
        this.globalService.showNotification('success', 'Your account is successfully created');
        _that.resetForm();
        _that.saveLinkedInDataInToken(res.data);
        _that._route.navigate(["/manufacturer", "info"]);
      }
      else {
        this._commonMethod.hideLoader();
        this.globalService.showNotification('error', res.errorMessage);
      }
    });
    setTimeout(function () {
      _that._route.navigate(['/main', 'successScreen']);
    }, 1000);
  }

  private saveLinkedInDataInToken(user: any): void {
    this._jwtService.saveToken(user, user.rememberme === "true");
  }
  public goToPlanScreen(): void {
    this._showSection = 'plans';
  }
  public goToCreditCardScreen(SubscriptionId) {
    let flag = this.selectSubscriptionPlan(SubscriptionId);
    if (flag == false) {
      return false;
    }
    if (!this.card) {
      this.card = elements.create('card', { hidePostalCode: true });
      this.card.mount(this.cardElement.nativeElement);
      this.card.addEventListener('change', this.cardHandler);
    }
    window.scroll(0, 0);
    this._showSection = "creditCard";
  }
  private resetForm(): void {
    this._signUpForm.reset();
    for (var name in this._signUpForm.controls) {
      this._signUpForm.controls[name].setErrors(null);
    }
    
  }
  private onChange({ error }): void {
    if (error) {
      this.creditCardError = error.message;
      return;
    } else {
      this.creditCardError = null;
    }
    this.cd.detectChanges();
  }
  private getStripePlans() {
    let _that = this;
    this._signupiService.GetStripePlans().subscribe(response => {
      if (response.success) {
        if (response.data) {
          _that.setSubscriptionPlans(response.data);
          //_that.getManufacturerPlans(response.data);
        }
      }
    });
  }


  private setSubscriptionPlans(plans: Array<ManufacturerPlan>): void {
    this.SubscriptionPlans.inventor = plans.filter(d => d.planName == 'Month' && d.subscriber == 1);
    this.SubscriptionPlans.monthly = plans.filter(d => d.planName == 'Month' && d.subscriber == 2 && d.planType === 'Free');
    this.SubscriptionPlans.yearly = plans.filter(d => d.planName == 'Year' && d.subscriber == 2);
  }


  public durationNameById(id: number): string {
    return id === 1 ? 'Month' : 'Year';
  }


  public checkDivLength(length: number) {
    return length <= 3 ? 'singleDiv' : 'doubleDiv';
  }

  public togglePlans(type) {
    this.planType = type;
  }
  ngOnDestroy() {
    if (this.card != undefined) {
      this.card.removeEventListener('change', this.cardHandler);
      this.card.destroy();
    }
  }

  //methods
}