import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { CommponMethod } from '../../Helper/CommonMethods';
import { SignUpService } from '../../services/SignUpService';
import { GlobalService } from '../../services/global.service';

@Component({
    selector: 'welcome-screen',
    templateUrl: './welcome-screen.component.html'
})
export class WelcomeScreenComponent {
    //local fields
    public _confirm: boolean = false;
    public _ShowEmailSection: boolean = false;
    public _forgotPasswordForm: FormGroup;
    public projectId: string;
    private _notifier: NotifierService;
    //constructor
    constructor(private _activatedRoute: ActivatedRoute, private globalService: GlobalService, private _signUpService: SignUpService, private _commonMethod: CommponMethod, private _router: Router, private _fb: FormBuilder, private __notifier: NotifierService) {
        let self = this;
        this._activatedRoute.queryParams.subscribe(params => {
            self.projectId = params["projectId"];
            if (params._flag) {
                self._confirm = (params._flag == "confirm" ? true : false);
            }

        });
        this._notifier = __notifier;
    }

    /** hooks **/
    ngOnInit() {
        this.initilizeForgotPasswordForm();
    }
    public ShowEmailSection(): void {
        this._ShowEmailSection = !this._ShowEmailSection;
    }

    private initilizeForgotPasswordForm(): void {
        this._forgotPasswordForm = this._fb.group({
            Email: this._fb.control("", [Validators.required, Validators.email])
        })
    }
    public get f() { return this._forgotPasswordForm.controls; }
    public onSubmit(): void {
        if (this._forgotPasswordForm.valid) {
            this._commonMethod.showLoader();
            let _that = this;

            this._signUpService.ResendConfirmation(this._forgotPasswordForm.value.Email, _that.projectId).subscribe(result => {
                if (result["success"]) {
                    this.globalService.showNotification("success", "Confirmation email has been sent");
                    _that._ShowEmailSection = false;
                }
                else {
                    this.globalService.showNotification("error", result["errorMessage"]);
                }
                _that._commonMethod.hideLoader();
            })
        }
    }
}