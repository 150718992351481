export const emailreg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^ <>() \[\]\\.,;: \s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const onlycharandspace = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
export const charonly = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
export const charnumandspace = /^(?:[A-Za-z0-9]+)(?:[A-Za-z0-9 _]*)$/;
export const areacode = /^(\+?\d{1,3}|\d{1,4})$/;
export const onlyNumber = /^[0-9]*$/;
export const strongPassword = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
export const passwordPattern = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}/
export const maxTenThousand = /^([1-9][0-9]{0,2}|1000)$/;
export const decLen = /^(?!\s*$)[-a-zA-Z0-9_:,.' ']{1,100}$/;
export const restrictSpecialChar = /^[^*|\":<>[\]{}`\\()';@&$]+$/;
export const datereg = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
export const charAndNum = /^[a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$/;
export const priceRange = /^(?:[0-9][0-9]{0,5}(?:\.\d{1,2})?|100000|100000.00)$/;
export const decimalOnly = /\d+(\.\d{1,2})?/;
export const validUrl = /(http(s?)):\/\//;

