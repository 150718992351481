export class SignUpModel {
    //constructor
    constructor() { }

    //fields
    FirstName: string;
    LastName: string;
    Email: string;
    Password: string
    ConfirmPassword: string;
    PhoneNumber: string;
    CountryCode:string;
    Referral:string;
    Role: Number;
    // CardholderName: string;
    // MaskedCardNumber: string;
    // CardExpiry: string;
    // CardToken: string;
    // CardType: string;
    // ProjectId:number;
    SubscriptionId: Number = 0;
}
export class CreditCardDetail {
    //constructor
    constructor() { }

    //fields
    CardholderName: string;
    MaskedCardNumber: string;
    CardExpiry: string;
    CardToken: string;
    CardType: string;
}