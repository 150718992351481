import { Component, OnInit, Output, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../../../core/services/data.service';
import { JwtService } from '../../../core/services/jwt.service';
import { messageService } from '../../services/messageService';
import { CommponMethod } from 'src/app/core/Helper/CommonMethods';
import { NotifierService } from 'angular-notifier';
import { MessageContainer } from '../../models/Inbox/MessageContainer';
import { Message } from '../../models/Inbox/Message';
import { NewMessage } from '../../models/Inbox/NewMessage';
import { NgForm, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageData } from 'src/app/core/Models/MessageData';
import { PageEvent } from '@angular/material/paginator';
import { MatAutocomplete } from '@angular/material';
import { Route } from '@angular/compiler/src/core';
import { debug } from 'util';

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.css']
})
export class InboxComponent implements OnInit {
  // local fields
  @ViewChild('form') ngForm: NgForm;
  @ViewChild('testElem') el: MatAutocomplete;
  @ViewChild('messageForm') reply: NgForm;
  @ViewChild('message') message: ElementRef;

  public pageEvent: PageEvent;
  public messageContainers: Array<MessageContainer> = new Array<MessageContainer>();
  public messages: Array<Message> = new Array<Message>();
  private reqModel: NewMessage = new NewMessage();
  public messageData: MessageData = new MessageData();
  public newMessageSection = 'showmessages';
  public queryTitle: string;
  private notifier: NotifierService;
  public pagingModel = { page: 1, pageSize: 10, totalRecords: 0 };
  public _role: string = null;
  public messageInfo = { userName: null, postedDate: null, aliceName: null };
  public userId: number;
  public comesFromSearchManu = false;
  public getParticularMessage = { pid: 0, sid: 0 }
  public messageTitle = 'New Message';
  public firstContainer: MessageContainer = null;
  // search autocomplete sections
  public searchTerm: FormControl = new FormControl();
  public inventorData = <any>[];
  public inventorProjects = <any>[];
  public manufacturerNamesTerm: FormControl = new FormControl();
  @ViewChild('ManuFacturereNameCtrl') ManuFacturereNameCtrl: ElementRef;
  @ViewChild(MatAutocomplete) matAutocomplete: MatAutocomplete;
  public manufacturerNamesData = <any>[];

  // constructor
  constructor(private jwtService: JwtService,
    private commonMethod: CommponMethod,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private _notifier: NotifierService,
    private dataService: DataService,
    private _activatedRoute: ActivatedRoute,
    private messageService: messageService) {
    let self = this;
    self.notifier = _notifier;
    // get user role
    self.getUserRole();
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.getUsersList(false);
    this.getUserId();
  }
  ngAfterViewInit() {
    this.message.nativeElement.focus();
    this.initilizeAutocompleteInputs();
  }
  public initilizeQueryParams(): void {
    let self = this;
    let params = self._activatedRoute.snapshot.queryParams;
    let sid = params['sid'];
    let pid = params['pid'];
    let id = params['id'];
    let name = params['name'];
    let title = params['title'];
    if (title == 'profile') {
      let userid = params['id'];
      let username = params['name'];
      self.queryTitle = title;
      let mdata = [{ manufacturerId: userid, manufacturerName: username }];
      self.selectManufacturerId(null, mdata[0]);
      self.comesFromSearchManu = true;
      self.manufacturerNamesData = [{ manufacturerId: id, manufacturerName: name }];
      self.manufacturerNamesTerm.patchValue(name);
      self.messageInfo.userName = username;
      self.newMessageSection = title;
    }
    else if (title == 'projectdetail') {
      let userid = params['id'];
      let username = params['name'];
      let projectId = params["projectId"];
      self.queryTitle = title;
      let mdata = [{ manufacturerId: userid, manufacturerName: username, projectId: projectId }];
      self.selectManufacturerId(null, mdata[0]);
      self.comesFromSearchManu = true;
      self.manufacturerNamesData = [{ manufacturerId: id, manufacturerName: name }];
      self.manufacturerNamesTerm.patchValue(name);
      self.messageInfo.userName = "";
      self.messageData.projectId = projectId;
      self.messageData.recieverId = userid;
      self.messageInfo.userName = username;
      self.getHistoryMessages(userid, projectId);
    }
    else if (title == 'listmanufac') {
      let sid = params['sid'];
      let pid = params['pid'];
      self.queryTitle = title;
      self.getParticularMessage.sid = sid;
      self.getParticularMessage.pid = pid;
    }
    else if (title == 'searchproject') {
      let email = params['email'];
      let projectId = params['projectId'];
      let projectName = params['projectName'];
      let recieverId = params['recieverId'];
      self.queryTitle = title;
      self.messageData.email = email;
      self.messageData.projectId = projectId;
      self.messageData.projectName = projectName;
      self.messageData.recieverId = recieverId;
      self.messageInfo.userName = email;
      self.searchTerm.patchValue(email);
      self.getHistoryMessages(recieverId, projectId);
    }
  }


  /******** utility methods ********/
  public getDate(param) {
    if (param) {
      return new Date(param + ' UTC');
    }
  }
  public getOnlyDate(param) {
    if (param) {
      var date = new Date(param);
      var formattedDate = date.getMonth() + '-' + date.getDate() + '-' + date.getFullYear();
      return formattedDate;
    }
  }
  private getUserId(): void {
    var data = this.jwtService.getTokenData();
    if (data) {
      if (data['isExpired']){
        this.router.navigate(['/manufacturer', 'plan-payment-detail']);
      }
      this.userId = data['userId'];
    }
  }
  public setClass(senderId: number): boolean {
    return (senderId === this.userId ? true : false);
  }
  public makeContainerActive(row: MessageContainer): boolean {
    if (row.projectId && row.projectId == this.firstContainer.projectId && row.senderId == this.firstContainer.senderId) {
      return true;
    }
  }
  private getUserRole() {
    let self = this;
    var _result = self.jwtService.getTokenData();
    this.getUserId();
    if (_result) {
      self._role = _result['role'];
      if (self._role == 'Inventor') {
        self.getInventorProjects();
      }
    }
  }
  public getDataFromLocalStorage() {
    return localStorage.getItem('messageData');
  }
  public getAliceName(name: string): string {
    if (name) {
      var names = name.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }
      return initials;
    }
  }

  /********* paging methods  **********/
  public changePage(event) {
    let _that = this;
    _that.pagingModel.page = (event.pageIndex + 1);
    _that.pagingModel.pageSize = event.pageSize;
    this.getUsersList(false);
    return event;
  }


  /********** methods for get data **********/
  public getInventorProjects(): void {
    let self = this;
    let inventorId = self.userId;
    self.commonMethod.showLoader();
    this.messageService.getInventorProjects(inventorId).subscribe(response => {
      if (response) {
        this.inventorProjects = response.data;
      }
      self.commonMethod.hideLoader();
    }, (error) => {
      self.commonMethod.hideLoader();
    });
  }
  public getManufacturerByParam(str: string) {
    let self = this;
    self.messageService.getManufacturers(str).subscribe(
      data => {
        if (data['data']) {
          self.manufacturerNamesData = [];
          self.manufacturerNamesData = data['data'] as any[];
        } else {
          self.manufacturerNamesData = [];
        }
      })
  }
  public getHistoryMessages(senderId: number, projectId: number): void {
    let _that = this;
    this.messageService.getMessagesByUser(projectId, senderId).subscribe(res => {
      if (res.success) {
        _that.messages = res.data;
        _that.newMessageSection = 'showmessages';
        _that.dataService.refreshNoifications({ 'refreshNotification': 'true' });
        _that.messages = res.data;
        _that.comesFromSearchManu = false;
        const usr = _that.messageContainers.find(d => d.projectId === projectId && d.senderId === senderId);
        if (usr != null) {
          _that.dataService.sendMessage({ totalCount: usr.unreadMessageCount });
          usr.unreadMessageCount = 0;
        }
      }
      else {
        _that.messageData.projectId = parseInt(projectId.toString());
        _that.messageData.recieverId = senderId;
        if (_that._role == "Manufacturer") {
          _that.newMessageSection = 'profile';
          let name = _that._activatedRoute.snapshot.queryParams["email"];
          _that.searchTerm.patchValue(name);
        }
        else {
          _that.newMessageSection = _that.queryTitle;
        }
      }
    });
  }
  public getUsersList(showMessages: boolean): void {
    let _that = this;
    _that.commonMethod.showLoader();
    _that.messageService.getUsersList(this.pagingModel.page, this.pagingModel.pageSize).subscribe(res => {
      if (res.success) {
        _that.commonMethod.hideLoader();
        if (res.data) {
          _that.messageContainers = res.data;
          let firstMessageContainer = _that.messageContainers[0];
          _that.pagingModel.totalRecords = firstMessageContainer.totalCount;
          _that.getFirstUserMessage(showMessages);
        } else {
          _that.messageContainers.length = 0;
        }
      } else {
        _that.commonMethod.hideLoader();
        _that.initilizeQueryParams();
      }
    }, (error) => {
      _that.commonMethod.hideLoader();
    });
  }
  public getFirstUserMessage(showMessages: boolean): void {
    let self = this;
    if (this.messageContainers.length > 0) {
      if (self.getParticularMessage.pid && self.getParticularMessage.sid) {
        self.firstContainer = self.getMessageContainer(self.getParticularMessage.pid, self.getParticularMessage.sid);
      } else {
        self.firstContainer = self.messageContainers[0];
      }
      if (!self.firstContainer) {
        self.firstContainer = self.messageContainers[0];
      }
      self.dataService.sendMessage({ unReadMsg: self.firstContainer.unreadMessageCount });
      var row = {
        postedDate: self.firstContainer.postedDate,
        senderId: self.firstContainer.senderId,
        projectId: self.firstContainer.projectId,
        senderName: self.firstContainer.senderName,
      };
      self.messageData.projectId = self.firstContainer.projectId;
      self.messageData.recieverId = self.firstContainer.senderId;
      self.getMessagesByUser(false, row, showMessages);
    }
  }
  public getMessagesByUser(clear: boolean = false, row, callFromHtml: boolean): void {
    let _that = this;
    _that.clearMessage();
    _that.firstContainer = row;
    _that.messageInfo.userName = row.senderName;
    _that.messageInfo.postedDate = row.postedDate;
    _that.jwtService.removeMessageData();
    this.messageService.getMessagesByUser(row.projectId, row.senderId).subscribe(res => {
      if (res.success) {
        if (!callFromHtml) {
          _that.initilizeQueryParams();
        }
        else if (callFromHtml) {
          _that.messageData.projectId = row.projectId;
          _that.messageData.recieverId = row.senderId;
          _that.newMessageSection = 'showmessages';
        }
        _that.dataService.refreshNoifications({ 'refreshNotification': 'true' });
        _that.messages = res.data;
        const usr = _that.messageContainers.find(d => d.projectId === row.projectId && d.senderId === row.senderId);
        if (usr != null) {
          _that.dataService.sendMessage({ totalCount: usr.unreadMessageCount });
          usr.unreadMessageCount = 0;
        }
      }
    });
  }

  private getMessageContainer(pid: number, sid: number) {
    let self = this;
    let container = self.messageContainers.find(d => d.projectId == pid && d.senderId == sid);
    return container;
  }

  /*************** Message methods ***************/
  public postNewMessage(form: NgForm) {
    let _that = this;
    if (form.valid) {
      // if (_that.reqModel.message) {
      _that.commonMethod.showLoader();
      _that.reqModel.projectId = this.messageData.projectId;
      _that.reqModel.senderId = this.messageData.recieverId;
      _that.messageService.postNewMessage(_that.reqModel).subscribe(res => {
        if (res.success) {
          _that.jwtService.removeMessageData();
          _that.manufacturerNamesTerm.patchValue('');
          _that.newMessageSection = 'showmessages';
          _that.resetMyForm(form);
          this.messageData.projectId = 0;
          this.messageData.recieverId = 0;
          _that.commonMethod.hideLoader();
          _that.getUsersList(true);
        } else {
          _that.commonMethod.hideLoader();
        }
      }, (error) => {
        _that.commonMethod.hideLoader();
      })
    } else {
      _that.notifier.notify('error', 'Please enter message');
    }
  }

  public cancelMessage(): void {
    this.clearMessage();
    this.newMessageSection = 'showmessages';
    this.getFirstUserMessage(true);
  }
  public clearMessage(): void {
    this.reqModel.message = '';
    this.clearAutocompleteInput();
    if (this.reply) {
      this.reply.reset();
      this.reply.resetForm();
    }
    this.manufacturerNamesTerm.patchValue('');
  }
  public clearAutocompleteInput(): void {
    this.searchTerm.reset();
    this.manufacturerNamesTerm.reset();
    this.manufacturerNamesData.length = 0;
  }
  public createNewMessage(): void {
    this.clearAutocompleteInput();
    this.messageData.projectId = 0;
    this.messageData.recieverId = 0;
    this.messageInfo.userName = null;
    this.newMessageSection = 'profile';
  }
  private resetMyForm(form: NgForm): void {
    this.searchTerm.reset();
    this.searchTerm.clearValidators();
    this.manufacturerNamesTerm.reset();
    this.manufacturerNamesTerm.clearValidators();
    this.reqModel.message = '';
    this.manufacturerNamesTerm.patchValue('');
    form.control.get('Message').clearValidators();
  }


  // auto complete methods
  public selectInventorData(evt, row): void {
    if (row) {
      this.messageData.projectId = row.projectId;
      this.messageData.recieverId = row.inventorId;
    }
  }
  public selectManufacturerId(evt, row): void {
    if (row) {
      this.messageData.recieverId = row.manufacturerId;
      this.messageData.projectId = 0;
    }
  }
  public initilizeAutocompleteInputs(): void {
    let self = this;
    self.searchTerm.valueChanges.subscribe(
      term => {
        if (term != '') {
          self.messageService.getInventorNames(term).subscribe(
            data => {
              if (data['data']) {
                self.inventorData = [];
                self.inventorData = data['data'] as any[];
              } else {
                self.inventorData = [];
              }
            })
        }
      });

    self.manufacturerNamesTerm.valueChanges.subscribe(
      term => {
        if (term != '') {
          self.getManufacturerByParam(term);
        }
      });
  }

}
